import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

const FAQ = () => {
  return (
    <div className="px-4 py-20">
      <div className="max-w-3xl mx-auto">
      <div class="flex-1 text-center">
            <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
            <p class="text-xs  font-semibold tracking-widest text-white uppercase">
             FAQs
            </p>
          </div>
              <h2 class="text-3xl mt-3 font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
              </h2>
              <p class="mt-4 text-base font-normal leading-7 text-gray-600 text-xl lg:mt-6 lg:leading-8">
              Ready to bring your vision to life? Let's get started! Whether you have a question, need a quote, or want to discuss your project, we're here to help.  
              </p>
            </div>
            <br/>
        <Question title="How much do your services cost?" >
          <p className="text-xl">We offer project-based pricing, which means we provide a flat fee for a specific project scope. This fee is agreed upon before we begin working on your project, so you know exactly what to expect. We take the time to understand your unique needs and goals, and provide a customized quote based on your project. </p>
        </Question>
        <Question title="How do you handle confidentiality and privacy?">
          <p className="text-xl">
           At E&E Media LLC, we take privacy seriously and are committed to protecting your personal information and project data. Here's how we handle privacy: <br/>
           <ul>
            <li className="mt-3 text-xl list-disc ">We collect only the necessary personal information to communicate with you and deliver our services.</li>
            <li className="mt-3 text-xl list-disc ">We use secure protocols to protect your data and project files during transfer and storage.</li>
            <li className="mt-3 text-xl list-disc ">We do not share your personal information or project data with third parties, except when required by law or to protect our rights and interests.</li>
            <li className="mt-3 text-xl list-disc ">We retain your data only for as long as necessary to deliver our services and as required by law.</li>
           </ul>
          </p>
        </Question>
        <Question title="How do you handle communication and collaboration during a project?">
          <p className=" text-xl list-disc ">
            We believe in open and efficient communication to deliver sucessful projects. We adapt our communication approach to suit your preferences, ensuring seamless collaboration and successful project outcomes.
          </p>
        </Question>
        <Question title="What is your policy on refunds or cancellations?">
          <p className="mt-3 text-xl list-disc ">
            <ul>
              <li className="mt-3 text-xl list-disc ">
                Refunds:
                <ul>
                <li className="mt-3 text-xl list-disc ">If we haven't started working on your project, we offer a full refund.</li>
                <li className="mt-3 text-xl list-disc ">If we've started working on your project, we refund the remaining balance after deduction the work completed.</li>
                </ul>
                </li>
              <li className="mt-3 text-xl list-disc ">
                Cancellations:
                <li className="mt-3 text-xl list-disc ">You can cancel your project at any time, and we'll stop work immediately.</li>
                <li className="mt-3 text-xl list-disc ">Please note that cancellations may incur a fee for work already completed.</li>
              </li>
              <li className="mt-3 text-xl list-disc ">
                Refund Processing:
                <li className="mt-3 text-xl list-disc ">Refunds will be processed within 5-7 business days.</li>
              </li>
            </ul>
            <br/> 
            Please note that our refund and cancellation policy may vary depending on the specific project terms and agreements. We'll always communicate with you clearly and fairly to resolve any issues.
          </p>
        </Question>
        <Question title="Can you help me with content creation or strategy?">
          <p className=" text-xl list-disc ">
           Absolutely! Our team of experts can assist you with content creation and strategy to elevate your brand's online presence. we will work closely with you to understand your brand's unique voice, tone, and messaging, and develop a content strategy that resonates with your audience and drives results.
          </p>
        </Question>
        <Question title="Do you offer ongoing maintenance and support?">
          <p className="text-xl list-disc ">
           We offer flexible maintenance and support plans to suit your needs. Reach out to use to learn more! Our maintenance and support services ensure your online presence remains secure, fast, and reliable, so you can focus on growing your business.
          </p>
        </Question>
        <Question title="Can I speak with a representative before starting a project?">
          <p className="text-xl list-disc ">
            Of course! We'd be happy to discuss your project with you before getting started. In fact, we encourage it!
          </p>
        </Question>
      </div>
    </div>
  );
};

const Question = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-slate-300"
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="py-6 w-full flex items-center justify-between gap-4"
      >
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgba(3, 6, 23, 1)",
            },
          }}
          className="text-xl font-medium text-left bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text"
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "rgb(124 58 237)",
            },
            closed: {
              rotate: "0deg",
              color: "#030617",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? "fit-content" : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-600"
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default FAQ;