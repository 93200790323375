import React from 'react'

const DesignPreferences = ({formData, setFormData}) => {
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
           9. Do you have a style guide, logo, colors, or fonts that we should follow?
        </label>
        <textarea
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Enter here..."
            required
            type="text"
            value={formData.styleGuide}
            onChange={(event) => setFormData({...formData, styleGuide: event.target.value})}
        />
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
     10. What kind of fonts and colors do you like? Is there anything you absolutely dislike?
    </label>
    <textarea
        className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="Enter here..."
        required
        type="email"
        value={formData.colors}
        onChange={(event) => setFormData({...formData, colors: event.target.value})}
    />
</div>
<div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
       11. Share any sources of inspiration you’d like us to pull from.
    </label>
    <textarea
        className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="Enter here..."
        required
        type="email"
        value={formData.inspiration}
        onChange={(event) => setFormData({...formData, inspiration: event.target.value})}
    />
</div>
</div>
</React.Fragment>
  )
}

export default DesignPreferences