import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight, FiLogIn } from "react-icons/fi";
import Contact from "../components/Contact";
import Features6 from "../components/Features6";
import WhyBuy from "../components/WhyBuy";
import FAQ from "../components/FAQ";
import FinalFeature from "../components/FinalFeature";
import WhyBuy1 from "../components/WhyBuy1";
const SingleService = () => {
  const location = useLocation();
  const formData = location.state;
  console.log(formData);

  const IMG_PADDING = 12;

  const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
    return (
      <div
        style={{
          paddingLeft: IMG_PADDING,
          paddingRight: IMG_PADDING,
        }}
      >
        <div className="relative h-screen">
          <StickyImage imgUrl={imgUrl} />
          <OverlayCopy heading={heading} subheading={subheading} />
        </div>
        {children}
      </div>
    );
  };

  const StickyImage = ({ imgUrl }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["end end", "end start"],
    });

    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

    return (
      <motion.div
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: `calc(100vh - ${IMG_PADDING * 2}px)`,
          top: IMG_PADDING,
          scale,
        }}
        ref={targetRef}
        className="sticky z-0 overflow-hidden rounded-3xl"
      >
        <motion.div
          className="absolute inset-0 bg-neutral-950/70"
          style={{
            opacity,
          }}
        />
      </motion.div>
    );
  };

  const OverlayCopy = ({ subheading, heading }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
      target: targetRef,
      offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
    const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

    return (
      <motion.div
        style={{
          y,
          opacity,
        }}
        ref={targetRef}
        className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
      >
        <div class="max-w-xl mx-auto text-center mt-4 mb-7">
    <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
      <p class="text-xs font-semibold tracking-widest text-white uppercase">
        learn more
      </p>
    </div>
  </div>
        <p className=" text-center text-4xl font-bold md:text-7xl">{heading}</p>
        <ExampleContent />
      </motion.div>
    );
  };
  const ExampleContent = () => (
    <div className="mx-auto max-w-5xl gap-8 px-4 pb-24 pt-12  ">
      <div className="mx-auto flex flex-col text-center">
        
        <p className="mb-4 text-xl text-white md:text-2xl">
          {formData?.description}
        </p>
        <br />
        <div className="flex justify-between lg:gap-0 gap-4 mx-auto lg:w-3/5">
          <a
            className="lg:px-20"
            href={
              formData?.title === "Graphic Design Services"
                ? "/graphic-design"
                : formData?.title === "Mobile App Services"
                ? "/mobile-apps"
                : formData?.title === "Website Design and Development"
                ? "/web-form"
                : formData?.title === "Website Maintenance and Support"
                ? "/web-maintenance"
                : '/'
            }
          >
            {/* <button className="w-full mx-auto rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
        Learn more <FiArrowUpRight className="inline" />
      </button> */}
            <button
              className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
        border-violet-300 px-4 py-2 font-semibold
        uppercase text-violet-300 transition-all duration-500
        
        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-violet-300
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-neutral-900
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
            >
              <FiLogIn />
              <span>Get Started!</span>
            </button>
          </a>
          <a
            href="https://calendly.com/enriquemgreene/e-e-media-llc-service-consultation"
            target="_blank"
            rel="noreferrer"
          >
            {/* <button className="w-full mx-auto rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
        Learn more <FiArrowUpRight className="inline" />
      </button> */}
            <button
              className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
        border-violet-300 px-4 py-2 font-semibold
        uppercase text-violet-300 transition-all duration-500
        
        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-violet-300
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-neutral-900
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
            >
              <FiLogIn />
              <span>Request Consultation</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
  return (
    <div className="bg-white py-20">
      <TextParallaxContent
        imgUrl={formData.imgSrc}
        heading={formData?.title}
      />
      <section class="py-12 bg-black sm:py-16 lg:py-20 xl:py-24" >
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-lg mx-auto text-center lg:max-w-2xl">
        <div class="max-w-xl mx-auto text-center mt-4 mb-10">
    <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
      <p class="text-xs font-semibold tracking-widest text-white uppercase">
        quality at it's finest
      </p>
    </div>
  </div>
            <h2 class="text-3xl font-normal text-white sm:mt-8 sm:text-4xl lg:text-5xl xl:text-6xl">{formData?.mainTitle}</h2>
            <p class="mt-6 text-lg font-normal text-gray-400">{formData?.mainDesc}</p>
        </div>

        {/* <div class="grid max-w-md grid-cols-1 gap-6 mx-auto mt-12 text-center lg:max-w-none lg:grid-cols-3 sm:mt-16 lg:mt-20">
            <div class="relative overflow-hidden bg-base-900 rounded-2xl">
                <div class="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> {formData?.subHeader} </span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl">{formData?.subTitle}</p>
                    <p class="mt-4 text-base font-normal text-gray-400">{formData?.subText}</p>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex items-center justify-center">
                    <img class="object-contain w-full" src={formData?.sub1} alt="" />
                </div>
            </div>

            <div class="relative overflow-hidden bg-base-900 rounded-2xl" >
                <div class="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> {formData?.subHeader1} </span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl">{formData?.subTitle1}</p>
                    <p class="mt-4 text-base font-normal text-gray-400">{formData?.subText1}</p>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex items-center justify-center px-8 pb-6">
                    <img class="object-contain w-full" src={formData?.sub2} alt="" />
                </div>
            </div>

            <div class="relative overflow-hidden bg-base-900 rounded-2xl">
                <div class="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> {formData?.subHeader2} </span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl">{formData?.subTitle2}</p>
                    <p class="mt-4 text-base font-normal text-gray-400">{formData?.subText2}</p>
                </div>

                <div class="absolute inset-x-0 bottom-0 flex items-center justify-center px-16 transform translate-y-24 sm:px-20 lg:translate-y-20 lg:px-12 xl:px-20">
                    <img class="object-contain w-full" src={formData?.sub3} alt="" />
                </div>
            </div>
        </div> */}
    </div>
</section>

      <Features6 />
      <WhyBuy1 />
      <FAQ />
      <Contact />
    </div>
  );
};

export default SingleService;
