import React from 'react'
import Hero from '../components/Hero'
import Features from '../components/Features'
import Features1 from '../components/Features1'
import Features2 from '../components/Features2'
import Features3 from '../components/Features3'
import Features4 from '../components/Features4'
import Services from '../components/Services'
import Features5 from '../components/Features5'
import Features6 from '../components/Features6'
import HowItWorks from '../components/HowItWorks'
import NFTFeature from '../components/NFTFeature'
import WhyBuy from '../components/WhyBuy'
import Contact from '../components/Contact'
import FAQ from '../components/FAQ'
import Portfolio from '../components/Portfolio'
import WhyBuy1 from '../components/WhyBuy1'
import { FaUserCheck } from "react-icons/fa6";
import satif from '../images/satif-removebg-preview.png'
import creav from '../images/creav.jpg'
import afford from '../images/afford1.webp'
import rocket from '../images/rocket.jpeg'
const Home = () => {
  return (
    <React.Fragment>
        <Hero />
       
        {/* <Features1 /> */}
        {/* <Features2 /> */}
        {/* <Features3 /> */}
        {/* <Features4 />
        <Features3 /> */}
        {/* <Services /> */}
        {/* <Features5 /> */}
        <Portfolio /> 
        <section class="py-12 bg-black sm:py-16 lg:py-20 xl:py-24" >
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-lg mx-auto text-center lg:max-w-2xl">
        <div class="max-w-xl mx-auto text-center mt-4 mb-10">
    <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
      <p class="text-xs font-semibold tracking-widest text-white uppercase">
        quality at it's finest
      </p>
    </div>
  </div>
            <h2 class="text-3xl font-normal text-white sm:mt-8 sm:text-4xl lg:text-5xl xl:text-6xl">The E&E Media Way</h2>
            <p class="mt-6 text-lg font-normal text-gray-400">At E&E Media, we are dedicated to building long lasting relationships with our clients, fueled by our core values of creativity, collaboration, and innovation. We strive to deliver exceptional web design solutions that empower your online presence and drive success.</p>
        </div>

        <div class="grid max-w-md grid-cols-1 gap-6 mx-auto mt-12 text-center lg:max-w-none lg:grid-cols-3 sm:mt-16 lg:mt-20">
            <div class="relative overflow-hidden bg-base-900 rounded-2xl">
                <div class="px-6 pt-8  sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> satisfaction assured</span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl"> 100% Satisfaction Guarantee</p>
                    <p class="mt-4 text-base font-normal text-gray-400">Before we hit the “launch” button, we ensure your complete approval. Your satisfaction is our top priority.</p>
                    <img class="mt-5 object-contain w-full" src={satif} alt="" />
                </div>

           
                  
             
            </div>

            <div class="relative overflow-hidden bg-base-900 rounded-2xl" >
                <div class="px-6 pt-8 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> bringing visions to life</span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl">Creative Expertise and Vision</p>
                    <p class="mt-4 text-base font-normal text-gray-400">We don’t just follow trends; we set them. Our innovative approach ensures your project stands out in a crowded digital landscape.</p>
                    <img class=" mt-5 object-contain w-full" src={rocket} alt="" />
                </div>


            </div>

            <div class="relative overflow-hidden bg-base-900 rounded-2xl">
                <div class="px-6 pt-8 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                    <h3 class="text-sm font-normal tracking-widest uppercase">
                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> exceptional value </span>
                    </h3>
                    <p class="mt-3 text-2xl font-normal text-white xl:text-3xl">Affordable Pricing</p>
                    <p class="mt-4 text-base font-normal text-gray-400">Quality doesn’t have to break the bank. We offer competitive pricing without compromising on excellence.</p>
                    <img  class=" mt-5 object-contain w-full" src={afford} alt="" />
                </div>


            </div>
        </div>
    </div>
</section>
        <Features6 />
        {/* <WhyBuy /> */}
        <WhyBuy1 />
        {/* <HowItWorks /> */}
        {/* <NFTFeature /> */}
        <FAQ />
        <Contact />
    </React.Fragment>
  )
}

export default Home