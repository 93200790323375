import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const Blog = () => {

  const query = "business advice for the entrepreneurs and small businesses";
  const apiKey = "b33b38ab7d284e55af1d044fa1551ad7";
  const encodedQuery = encodeURIComponent(query);
  const apiUrl = `https://newsapi.org/v2/everything?q=${encodedQuery}&apiKey=${apiKey}`;
  const [data, setData] = useState("");
  useEffect(() => {
    // Fetch data from the News API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setData([...data.articles]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array to run the effect only once
  console.log(data);



  return (
    <section class="py-40">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div class="max-w-xl mx-auto text-center mb-20">
            <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
                <p class="text-xs font-semibold tracking-widest text-white uppercase">Blogs</p>
            </div>
            <h2 class="mt-6 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Latest Blogs on Business and Startups</h2>
            <p class="mt-4 text-base leading-relaxed text-gray-600">Remember, our slogan says it all: “Your vision, our mission.” We’re here to turn your ideas into reality and elevate your online presence!</p>
        </div>

        <div class="grid grid-cols-1 gap-6 px-8 mt-12 sm:mt-16 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:px-0">
          {data &&
            data.filter(obj => obj.title !== '[Removed]' && obj.urlToImage !== null)
            .map((obj, index) => (
              <div
                key={index}
                class="flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow group rounded-xl hover:shadow-lg hover:-translate-y-1"
              >
                 <a target="_blank" rel="noreferrer" href={obj.url} className="flex shrink-0 aspect-w-4 aspect-h-3">
                  <img
                    class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110"
                    src={obj.urlToImage}
                    alt=""
                  />
                </a>
                <div class="flex-1 px-4 py-5 sm:p-6">
                  <a target="_blank" rel="noreferrer" href={obj.url} title="" class="">
                    <p class="text-lg font-bold text-gray-900">{obj.title}</p>
                    <p class="mt-3 text-sm font-normal leading-6 text-gray-500 line-clamp-3">
                      {obj.description}
                    </p>
                  </a>
                </div>
                <div class="px-4 py-5 mt-auto border-t border-gray-100 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-2">
                      <p class="text-sm font-medium text-gray-900">
                        <a target="_blank" rel="noreferrer" href={obj.url} title="" class="">
                          {" "}
                          {obj.source.name}
                        </a>
                      </p>
                      {/* <span class="text-sm font-medium text-gray-900"> • </span>
                            <p class="text-sm font-medium text-gray-900">7 Mins Read</p> */}
                    </div>

                    <a target="_blank" rel="noreferrer" href={obj.url} title="" class="" role="button">
                      <svg
                        class="w-5 h-5 text-gray-300 transition-all duration-200 group-hover:text-gray-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <line x1="17" y1="7" x2="7" y2="17"></line>
                        <polyline points="8 7 17 7 17 16"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
