import React from 'react'
import { FormControlLabel, Switch, Select, MenuItem, Checkbox, ListItemText } from '@mui/material'
const TechnicalRequirements = ({formData, setFormData}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const selectedTech = [
        'Google Maps API',
        'Facebook API',
        'Instagram API',
        'YouTube API',
        'PayPal API',
        'Stripe API',
        'Amazon API',
        'Other'
    ]
    const [newTech, setTech] = React.useState([]);

    // Retrieve the stored checkbox values from local storage on component mount
React.useEffect(() => {
    const storedTech = localStorage.getItem('selectedTech');
    if (storedTech) {
      setTech(JSON.parse(storedTech));
    }
  }, []);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setTech(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    // Store the selected checkbox values in local storage whenever they change
React.useEffect(() => {
    localStorage.setItem('selectedTech', JSON.stringify(newTech));
  }, [newTech]);
  

  const handlePageRefresh = () => {
    // Clear stored features on page refresh
    localStorage.removeItem('selectedTech');
    setTech([]); // Clear the state as well
  };

  // Event listener for page refresh
  React.useEffect(() => {
    window.addEventListener('beforeunload', handlePageRefresh);
    return () => {
      window.removeEventListener('beforeunload', handlePageRefresh);
    };
  }, []);
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
        17. Domain Name? (Don't worry if you don't have one yet. We'll help you get set up!)
        </label>
        <br/>
          <FormControlLabel  control={<Switch  value={formData.domain} checked={formData.domain}  onChange={(event) => setFormData({...formData, domain: event.target.checked})}  />} label={formData.domain ? 'Yes' : 'No'} />
        {formData.domain && (
        <input
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="text"
            placeholder="First Name"
            required
            type="text"
            value={formData.domainName}
            onChange={(event) => setFormData({...formData, domainName: event.target.value})}
        /> )}
    </div>
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
           18. Hosting (Already Set Up or Need Assistance?)
        </label>
        <br/>
          <FormControlLabel  control={<Switch  value={formData.hosting}   onChange={(event) => setFormData({...formData, hosting: event.target.checked})}  />} label={formData.hosting ? 'Yes' : 'No'} />
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
    19. Any Specific Technologies or Platforms Preferred?
    <br/>
    <br/>
    Please select "Other" if you'd like more than one!
    </label>
    {/* <input
        className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="this will be a dropdown"
        required
        type="email"
    value={formData.tech}
        onChange={(event) => setFormData({...formData, tech: event.target.value})}
    /> */}
      <br/>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={formData.tech === 0 ? newTech: formData.tech}
          onChange={(event) => {
            handleChange(event);
            setFormData({ ...formData, tech: event.target.value });
          }}
          displayEmpty
          renderValue={value => {
            if (value.length === 0) {
              return <em>Select Tech...</em>;
            }
            return value;
          }}
          className='w-[100%]'
          MenuProps={MenuProps}
        >
          {selectedTech.map((tech) => (
            <MenuItem key={tech} value={tech}>
              <Checkbox checked={formData?.tech.includes(tech)} />
              <ListItemText primary={tech} />
            </MenuItem>
          ))}
        </Select>
</div>
</div>
</React.Fragment>
  )
}

export default TechnicalRequirements