import React from 'react'
import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import main from '../images/main.jpeg'
import web from '../images/webdesign.jpeg'
import mobile from '../images/mobile.jpeg'
import graphic from '../images/graphic.jpeg'
import ServGrid from '../components/ServGrid';
import Contact from '../components/Contact';
const GetStarted = () => {
    const Card = ({ heading, description, imgSrc, href }) => {
        return (
            <a href={href}>     
          <motion.div
            transition={{
              staggerChildren: 0.035,
            }}
            whileHover="hover"
            className="w-full h-64 overflow-hidden cursor-pointer group relative"
          >
      
            <div
              className='absolute inset-0 group group-hover:scale-110 transition-all duration-500'
              style={{
                backgroundColor: 'black',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
                 <div
              className="absolute inset-0"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              }}
            />
            </div>

            <div className="p-4 relative text-white z-20 h-full text-white group-hover:text-white transition-colors duration-500 flex flex-col items-center">
              <FiArrowRight className="text-3xl group-hover:-rotate-45 transition-transform duration-500 ml-auto" />
              <div className='text-center flex flex-col mx-auto'>
                <br/>
                <br/>
                <h4 >
                  {heading.split("").map((l, i) => (
                    <ShiftLetter letter={l} key={i} />
                  ))}
                </h4>
                <p className='text-xl'>{description}</p>
              </div>
            </div>
          </motion.div>
          </a> 
        );
      };
      
      const ShiftLetter = ({ letter }) => {
       return (
          <div className="inline-block overflow-hidden h-[36px] font-semibold text-3xl">
            <motion.span
              className="flex flex-col min-w-[4px]"
              style={{
                y: "0%",
              }}
              variants={{
                hover: {
                  y: "-50%",
                },
              }}
              transition={{
                duration: 0.5,
              }}
            >
              <span>{letter}</span>
              <span>{letter}</span>
            </motion.span>
          </div>
        );
      };
      
  return (
    <>
    <div className="bg-slate-100 py-40 p-4">
         <div class="max-w-xl mx-auto text-center mb-10">
         <div class="inline-flex mb-5 px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
                <p class="text-xs font-semibold tracking-widest text-white uppercase">get started</p>
            </div>
            <h2 class="text-4xl font-bold text-gray-900 font-pj">Where would 
            <span className="text-[#007EFF]"> YOU </span>
             like to begin?</h2>
            <p class="max-w-sm mx-auto mt-5 text-base text-xl font-normal text-gray-600 font-pj">Our services are tailor-made for each client. Promote your business and grow with us! Let's get started!</p>
        </div>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-8 w-full max-w-6xl mx-auto">
      
      <Card
        heading="Web Design"
        href="/web-form"
        description="Ready to Elevate Your Digital Presence? Start Your Web Design Journey Today!"
        imgSrc={web}
      />
      <Card
        heading="Graphic Design "
        href='/graphic-design'
        description="From Logos to Marketing Collateral, We’ve Got You Covered. Discover the Power of Visual Storytelling!"
        imgSrc={graphic}
      />
      <Card
        heading="Website Maintenance "
        href='/web-maintenance'
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, exercitationem."
        imgSrc={main}
      />
      {/* <Card
        heading="Mobile Apps"
        href='/mobile-apps'
        description="Ready to Innovate? Begin Your Mobile App Project and Reach Your Audience on the Go!"
        imgSrc={mobile}
      /> */}
    </div>
  </div>
  <ServGrid />
  <Contact />
  </>
  )
}

export default GetStarted