import React from 'react'

const AdditionalCommentsandQuestions = ({formData, setFormData}) => {
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
        22. Any additional information we may need?
        </label>
        <textarea
            className="text-center h-48 md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="text"
            placeholder="Write your message..."
            required
            type="text"
            value={formData.additional}
            onChange={(event) => setFormData({...formData, additional: event.target.value})}
        />
    </div>
</div>
</React.Fragment>
  )
}

export default AdditionalCommentsandQuestions