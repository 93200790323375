import React, { useMemo, useState } from "react";
import svg from "../images/offlogo.png";
import { FiMenu, FiArrowRight, FiX, FiChevronDown } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import {
  useMotionValueEvent,
  AnimatePresence,
  useScroll,
  motion,
} from "framer-motion";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import main from "../images/main.jpeg";
import web from "../images/webdesign.jpeg";
import mobile from "../images/mobile.jpeg";
import graphic from "../images/graphic.jpeg";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 250 ? true : false);
  });

  return (
    <nav
      className={`fixed top-0 z-50 w-full px-6 text-white 
      transition-all duration-300 ease-out lg:px-12
      ${
        scrolled ? "bg-neutral-950 py-3 shadow-xl" : "bg-black py-6 shadow-none"
      }`}
    >
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <Logo />
        <div className="hidden gap-6 lg:flex">
          <Links />
          <CTAs />
        </div>
        <MobileMenu />
      </div>
    </nav>
  );
};

const Logo = () => {
  // Temp logo from https://logoipsum.com/
  return (
    <a href="/">
      <img src={svg} alt="logo" className="lg:w-72 w-64 " />
    </a>
  );
};

const Links = () => {
  return (
    <div className="flex items-center gap-6">
      {LINKS.map((l) => (
        <NavLink key={l.text} href={l.href} FlyoutContent={l.component}>
          {l.text}
        </NavLink>
      ))}
    </div>
  );
};

const NavLink = ({ children, href, FlyoutContent }) => {
  const [hovered, setHovered] = useState(false);

  const showFlyout = FlyoutContent && hovered;

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="relative h-fit w-fit"
    >
      <a href={href} className="relative">
        {children}
        <span
          style={{
            transform: hovered ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-purple-500 transition-transform duration-300 ease-out"
        />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-white text-black"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const CTAs = () => {
  return (
    <div className="flex items-center gap-3">
      <a
        href="https://calendly.com/enriquemgreene/e-e-media-llc-service-consultation"
        target="_blank"
        rel="noreferrer"
      >
        <button className="rounded-lg border-2 border-indigo-300 bg-indigo-300 px-4 py-2 font-semibold text-black transition-colors hover:border-indigo-600 hover:bg-indigo-600 hover:text-white">
          Request Consultation
        </button>
      </a>
    </div>
  );
};

const ServicesContent = () => {
  const navigate = useNavigate();
  return (
    <div className="grid h-fit w-full grid-cols-12 shadow-xl lg:h-72 lg:w-[600px] lg:shadow-none xl:w-[750px]">
      <div className="col-span-12 flex flex-col justify-between bg-neutral-950 p-6 lg:col-span-4">
        <div>
          <h2 className="mb-2 text-xl font-semibold text-white">
            E&E Media LLC.
          </h2>
          <p className="mb-6 max-w-xs text-sm text-neutral-400">
            Your Vision, Our Mission.
          </p>
          <p className="mb-6 max-w-xs text-sm text-neutral-400">
            At E&E Media LLC., we tirelessly work on apps and websites until
            they meet our 100% Satisfaction Guarantee. Our mission is to empower
            businesses with digital excellence, ensuring their success through
            personalized solutions and unwavering dedication.
          </p>
        </div>
        <a
          href="/get-started"
          className="flex items-center gap-1 text-xs text-indigo-300 hover:underline"
        >
          Learn more <FiArrowRight />
        </a>
      </div>
      <div className="col-span-12 grid grid-cols-2 grid-rows-2 gap-3 bg-white p-6 lg:col-span-8">
        <a
          href="/single-service"
          onClick={() =>
            navigate("/single-service", {
              state: {
                mainTitle: 'Web Design Services',
                mainDesc: 'Transform your online presence with our custom web design solutions, crafted to engage, inform, and convert. From responsive websites to e-commerce platforms, our expert designers create intuitive, user-friendly, and visually stunning experiences that drive results for your business.',
                subHeader: "Transform your online presence",
                subTitle: "Web Design that Drives Results",
                subText: "Elevate your brand and engage your audience with our expert web design services. From modern responsive designs to e-commerce solutions, we create websites that drive traffic, boost conversions, and grow your business",
                subHeader1: 'Where Creativity Meets Code' , 
                subTitle1: 'Crafting a Digital Presence That Shines', 
                subText1: "From concept to launch, I'll guide you through the web design process, ensuring a website that accurately reflects your brand's unique personality and resonates with your target audience.", 
                subHeader2: 'Tell Your Story' , 
                subTitle2: 'Building Digital Experiences', 
                subText2: "With a focus on user-centered design and cutting-edge technology, I'll create a website that not only looks amazing but also drives results, whether that means generating leads, boosting sales, or simply sharing your story with the world",
                title: "Website Design and Development",
                imgSrc: web,
                description:
                  "We specialize in creating custom websites that align with your brand identity and business goals. Whether you need a sleek portfolio site, an e-commerce platform, or a corporate web presence, we’ll design and develop a visually appealing, responsive, and user-friendly website.",
              },
            })
          }
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
        >
          <h3 className="mb-1 font-semibold">Web Design & Development</h3>
          <p className="text-xs">
            We craft visually appealing, mobile-friendly websites optimized for
            search engines.
          </p>
        </a>
        <a
          href="/single-service"
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
          onClick={() =>
            navigate("/single-service", {
              state: {
                mainTitle: 'Design and Development Solutions',
                mainDesc: 'Engage your audience and elevate your brand with our high-quality content creation services. ',
                subHeader: "Crafting Unique Identities",
                subTitle: "Logo Design",
                subText: "A well-designed logo is the foundation of your brand's visual identity. Our expert logo design services create distinctive, memorable, and scalable logos that represent your brand's values and message.",
                subHeader1: 'Compelling Stories, Crafted with Care' , 
                subTitle1: 'Content Creation', 
                subText1: 'Engage your audience and elevate your brand with our high-quality content creation services. From blog posts and articles to social media and website copy, our expert writers craft compelling stories that resonate with your target audience and drive results.',
                subHeader2: 'Make a Lasting Impression' , 
                subTitle2: 'Business Cards', 
                subText2: 'Our expertly designed business cards help you stand out from the crowd and make a professional impression. With high-quality materials, precise printing, and creative design, your business cards will become an essential tool for networking and building your brand.',
                title: "Graphic Design Services",
                imgSrc: graphic,
                description:
                  "Visuals matter! From eye-catching banners to icons and infographics, we’ll create graphics that enhance your brand presence. Whether it’s for social media, newsletters, or promotional materials, our designs will captivate your audience.",
              },
            })
          }
        >
          <h3 className="mb-1 font-semibold">Graphic Design Services</h3>
          <p className="text-xs">
            Using high-quality images and visually appealing typography, we
            guarantee to make your designs stand out.
          </p>
        </a>
        {/* <a
          href="/single-service"
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
          onClick={() =>
            navigate("/single-service", {
              state: {
                mainTitle: 'Designing Apps, Elevating Experiences',
                mainDesc: 'Our mobile app design service crafts intuitive, user-centered, and visually stunning experiences that bring vision to life and leave lasting impression on your users.',
                subHeader: "User-centric Approach",
                subTitle: "Understanding Your Users",
                subText: "Our user research and analysis ensure that we design apps that meet the needs and exceed the expectations of your target audience.",
                subHeader1: "Innovative Design Solutions",
                subTitle1: "Designing for Delight",
                subText1: "Our team of expert designers and creatives work together to craft unique, engaging, and user-friendly interfaces that drive user adoption and loyalty",
                subHeader2: "Seamless User Experience",
                subTitle2: "Streamlining interactions",
                subText2: "*We optimize every touchpoint and interaction to create a seamless and intuitive user experience that sets your app apart from the competition.",
                title: "Mobile App Services",
                imgSrc: mobile,
                description:
                  " We specialize in crafting bespoke mobile apps that seamlessly align with your brand vision and business objectives. Whether you’re envisioning an elegant lifestyle app, a robust e-commerce platform, or a cutting-edge corporate solution, we’ll meticulously design and develop an app that captivates users.",
              },
            })
          }
        >
          <h3 className="mb-1 font-semibold">Mobile App Services</h3>
          <p className="text-xs">
            We understand the importance of staying connected on-the-go. That's
            why we've developed a range of mobile services designed to make your
            life easier and more convenient.
          </p>
        </a> */}
        <a
          href="/single-service"
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
          onClick={() =>
            navigate("/single-service", {
              state: {
                mainTitle: 'Protecting Your Online Presence',
        mainDesc: 'Our web maintenance service ensures your website remains secure, fast, and up-to-date, giving you peace of mind and allowing you to focus on growing your business',
        subHeader: "Proactive Security Measures",
        subTitle: "Safeguarding Your Website",
        subText: "We monitor your website's security 24/7, applying updates and patches to prevent vulnerabilities and protect against threats.",
        subHeader1: "Optimized Performance",
        subTitle1: "Speed and Reliability",
        subText1: "Our optimization techniques ensure your website loads quickly and runs smoothly, improving user experience and search engine rankings.",
        subHeader2: "Expert Support",
        subTitle2: "Trusted Guidance",
        subText2: "Our expert team is always available to assist with any issues or questions, providing personalized support and guidance.",
                title: "Website Maintenance and Support",
                imgSrc: main,
                description:
                  "Keeping your website secure and up-to-date is crucial. We’ll handle software updates, security patches, and backups. With ongoing support, you can focus on your business while we take care of the technical details.",
              },
            })
          }
        >
          <h3 className="mb-1 font-semibold">Web Maintenance & Support</h3>
          <p className="text-xs">
            Our team is dedicated to providing top-notch web maintenance
            services to ensure your website remains secure, fast, and
            user-friendly.
          </p>
        </a>
      </div>
    </div>
  );
};

const AboutUsContent = () => {
  return (
    <div className="w-full bg-white p-6 shadow-none lg:w-[200px] lg:shadow-xl">
      <div className="grid lg:grid-cols-1">
        <div className=" space-y-3">
          <a href="/about-us" className="block text-md hover:underline">
            <h3 className="font-bold text-center">ABOUT US</h3>
          </a>

          <a href="/blog" className="block text-md hover:underline">
            <h3 className="font-bold text-center">BLOGS </h3>
          </a>
        </div>
      </div>
    </div>
  );
};

const MobileMenuLink = ({ children, href, FoldContent, setMenuOpen }) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(false);

  return (
    <div className="relative text-neutral-950">
      {FoldContent ? (
        <div
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
          onClick={() => setOpen((pv) => !pv)}
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(false);
            }}
            href={href}
          >
            {children}
          </a>
          <motion.div
            animate={{ rotate: open ? "180deg" : "0deg" }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
          >
            <FiChevronDown />
          </motion.div>
        </div>
      ) : (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(false);
          }}
          href={href}
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
        >
          <span>{children}</span>
          <FiArrowRight />
        </a>
      )}
      {FoldContent && (
        <motion.div
          initial={false}
          animate={{
            height: open ? height : "0px",
            marginBottom: open ? "24px" : "0px",
            marginTop: open ? "12px" : "0px",
          }}
          className="overflow-hidden"
        >
          <div ref={ref}>
            <FoldContent />
          </div>
        </motion.div>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="block lg:hidden">
      <button onClick={() => setOpen(true)} className="block text-3xl">
        <FiMenu />
      </button>
      <AnimatePresence>
        {open && (
          <motion.nav
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            exit={{ x: "100vw" }}
            transition={{ duration: 0.15, ease: "easeOut" }}
            className="fixed left-0 top-0 flex h-screen w-full flex-col bg-white"
          >
            <div className="flex items-center justify-between p-6">
              <Logo color="black" />
              <button onClick={() => setOpen(false)}>
                <FiX className="text-3xl text-neutral-950" />
              </button>
            </div>
            <div className="h-screen overflow-y-scroll bg-neutral-100 p-6">
              {LINKS.map((l) => (
                <MobileMenuLink
                  key={l.text}
                  href={l.href}
                  FoldContent={l.component}
                  setMenuOpen={setOpen}
                >
                  {l.text}
                </MobileMenuLink>
              ))}
            </div>
            <div className="flex justify-end bg-neutral-950 p-6">
              <CTAs />
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;

const LINKS = [
  {
    text: "Home",
    href: "/",
  },
  {
    text: "Services",
    href: "/services",
    component: ServicesContent,
  },
  {
    text: "About Us",
    href: "/about-us",
    // component: AboutUsContent,
  },
  // {
  //   text: "Portfolio",
  //   href: "/portfolio",
  // },
  {
    text: "Contact Us",
    href: "/contact",
  },
  // {
  //   text: "Courses",
  //   href: "/courses",
  // }
];
