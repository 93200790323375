import React from 'react'
import GraphicDesignForm from '../components/GraphicComponents/GraphicDesignForm'

const GraphicForm = () => {
  return (
    <section className="py-40 bg-gradient-to-br from-blue-100/40 to-purple-100/40">
    <div className="lg:w-[70%] mx-auto  gap-24 ">
        <div className="text-center md:w-3/5 w-4/5 mx-auto">
        <div class="max-w-xl mx-auto text-center mb-5">
            <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
                <p class="text-xs font-semibold tracking-widest text-white uppercase">services</p>
            </div>
        </div>
            <h1 className="text-4xl font-semibold text-[#060C3C]">
            Graphic Design & {' '}
                <span className="text-[#007EFF]">
                Branding  {' '}
                </span>
                 Form
            </h1>
            <p className="text-[#56687B] pt-4 font-normal">
                If you have any queries, be sure to let us know through our contact methods below. We can also be contacted via our form below too, so just send us a message and we'll be in touch!
            </p>
        </div>
       <GraphicDesignForm />
    </div>
</section>
  )
}

export default GraphicForm