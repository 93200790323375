import React from 'react'
import strip from '../images/4375133_logo_youtube_icon.png'
import tick from '../images/4362958_tiktok_logo_social media_icon.png'
import amazon from '../images/2062062_amazon_buy_logo_online_shop_icon.png'
const ServGrid = () => {
  return (
    <section class="py-12 bg-gray-900 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 class="text-sm font-bold tracking-widest text-gray-400 uppercase font-pj">Integrations</h2>
        <p class="max-w-xl mt-8 text-3xl font-bold text-white xl:max-w-3xl sm:text-4xl xl:text-5xl font-pj">Connect your website or app with 1300+ popular tools</p>

        <svg class="w-auto h-4 mt-8 text-gray-600 md:mt-16" viewBox="0 0 172 16" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 116 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 151 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 88 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 123 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 158 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 95 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 130 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 165 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 102 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 137 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 172 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 109 1)" />
            <line y1="-0.5" x2="18.0278" y2="-0.5" transform="matrix(-0.5547 0.83205 0.83205 0.5547 144 1)" />
        </svg>

        <div class="grid grid-cols-1 gap-6 mt-12 sm:grid-cols-2 lg:grid-cols-3 md:mt-20">
            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src="https://cdn.rareblocks.xyz/collection/clarity/images/integrations/2/logo-spotify.png" alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">Spotify</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Enhance your website by allowing users to control Spotify music directly from your site. This integration can create a seamless music experience for visitors.</p>
                </div>
            </div>

            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src="https://cdn.rareblocks.xyz/collection/clarity/images/integrations/2/logo-play-store.png" alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">Play Store</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Integrate your app with the Google Play Store, making it easy for users to download and install your app on Android devices.</p>
                </div>
            </div>

            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src={strip} alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">YouTube</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Embed YouTube videos on your website to showcase content, tutorials, or promotional material. This integration enhances engagement and visual appeal.</p>
                </div>
            </div>

            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src={amazon} alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">Amazon</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Connect your e-commerce site to Amazon for product listings, inventory management, and streamlined checkout. This integration expands your reach to Amazon’s vast customer base.</p>
                </div>
            </div>

            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src={tick} alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">TiKTok</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Enable TikTok sharing features, allowing users to share content directly from your website to their TikTok profiles. This integration boosts visibility and user-generated content.</p>
                </div>
            </div>

            <div class="overflow-hidden bg-gray-800 rounded-xl">
                <div class="p-8">
                    <img class="w-auto h-8" src="https://cdn.rareblocks.xyz/collection/clarity/images/integrations/2/logo-facebook.png" alt="" />
                    <h3 class="mt-8 text-xl font-bold text-white font-pj">Facebook</h3>
                    <p class="mt-5 text-lg font-normal text-gray-400 font-pj">Integrate Facebook for social login, sharing buttons, and audience engagement. Users can easily log in using their Facebook credentials and share your content with their network.</p>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default ServGrid