import React from 'react'
import { Stack, InputNumber } from 'rsuite'
import 'rsuite/dist/rsuite-no-reset.min.css';
import { FormControlLabel, Switch } from '@mui/material';
const ContentandPages = ({formData, setFormData}) => {
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
        14. Number of Pages (Approximate): 
        </label>
       <Stack style={{marginTop: 10}}>
    <InputNumber min={0} max={99} defaultValue={0} value={formData.numberPages} onChange={(event) => setFormData({...formData, numberPages: event})}/>
  </Stack>
    </div>
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
           15. Content Ready? (Yes/No)
        </label>
        <br/>
          <FormControlLabel  control={<Switch  value={formData.contentReady} onChange={(event) => setFormData({...formData, contentReady: event.target.checked})}  />} label={formData.contentReady ? 'Yes' : 'No'} />
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
    16. Existing Logo or Need Logo Design?
    </label>
      <br/>
          <FormControlLabel  control={<Switch  value={formData.logo} onChange={(event) => setFormData({...formData, logo: event.target.checked})}  />} label={formData.logo ? 'Yes' : 'No'} />
</div>
</div>
</React.Fragment>
  )
}

export default ContentandPages