import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppOverview from "./AppOverview";
import Features from "./Features";
import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle } from "react-icons/fi";
import ClientInformation from "../WebDesignComponents/ClientInformation";
import emailjs from '@emailjs/browser';
import logo from '../../images/offlogo.png'
const MobileAppForm = () => {
    const sendEmail = () => {
        const htmlContent = (
          <div className="py-40" style={{ backgroundColor: '#ffffff', fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif' }}>
            <table align="center" width="100%" role="presentation" cellSpacing="0" cellPadding="0" border="0" style={{ maxWidth: '37.5em', margin: '0 auto', padding: '20px 0 48px' }}>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td>
                    <img alt="E&E Media" src={logo} width="auto" height="auto" style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', margin: '0 auto' }} />
                    <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Thank you for reaching out to us! We’ve received your information from the form you submitted and will be emailing you shortly. Our team is excited to learn more about your project! To discuss your needs further, you can schedule a consultation with us at your convenience.</p>
                    <table align="center" width="100%" style={{ textAlign: 'center' }} border="0" cellPadding="0" cellSpacing="0" role="presentation">
                      <tbody>
                        <tr>
                          <td>
                            <a href="https://calendly.com/enriquemgreene/e-e-media-llc-service-consultation" target="_blank" rel="noreferrer" style={{ backgroundColor: '#5F51E8', borderRadius: '3px', color: '#fff', fontSize: '16px', textDecoration: 'none', textAlign: 'center', display: 'inline-block', lineHeight: '100%', maxWidth: '100%', padding: '12px 12px 12px 12px' }}>
                              <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%;mso-text-raise: 18;" hidden>&nbsp;</i><![endif]--> */}</span>
                              <span style={{ display: 'inline-block', lineHeight: '120%', maxWidth: '100%', msoPaddingAlt: '0px', msoTextRaise: '9px' }}>Get started</span>
                              <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%" hidden>&nbsp;</i><![endif]--> */}</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Best,<br />The E&E Media LLC team</p>
                    <hr style={{ border: 'none', borderTop: '1px solid #eaeaea', width: '100%', borderColor: '#cccccc', margin: '20px 0' }} />
                    <p style={{ fontSize: '12px', lineHeight: '24px', margin: '16px 0', color: '#8898aa', textAlign: 'center' }}>© Copyright 2024, All Rights Reserved by E&E Media LLC.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>)
   
      
        const emailParams = {
            from_name: 'E&E Media LLC',
            user_email: formData?.email,
            to_name: formData?.firstName,
        };
      
        emailjs.send('service_zrw3gw3', 'template_haq8erl', emailParams, 'Jte4dftsQ1ppT40QC', {
            publicKey: 'Jte4dftsQ1ppT40QC',
            to_name: formData?.firstName,
            from_name: 'Enrique',
            message: htmlContent,
        })
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((error) => {
            console.log('FAILED...', error);
          });
          const additionalEmailContent = `
          Mobile Form Information
          First Name: ${formData?.firstName}
          Last Name: ${formData?.lastName}
          Email: ${formData?.email}
          Phone Number: ${formData?.phoneNumber}
          Target Audience: ${formData?.targetAudience}
          Platforms: ${formData?.platforms}
          Mockups: ${formData?.mockups}
          Features: ${formData?.features}
          Design Preferences: ${formData?.designPreferences}
          Budget: ${formData?.budget}
        `;
      
        // Send the additional email
        const additionalEmailParams = {
          from_name: 'Client',
          to_email: 'enriquemgreene@gmail.com',
          user_email: 'enriquemgreene@gmail.com',
          to_name: formData?.firstName,
          message: additionalEmailContent,
        };
      
        emailjs.send('service_zrw3gw3', 'template_471dl49', additionalEmailParams, 'Jte4dftsQ1ppT40QC',)
          .then((response) => {
            console.log('Additional email sent successfully!', response.status, response.text);
          })
          .catch((error) => {
            console.log('Failed to send additional email...', error);
          });
          
      };
  const [isOpen, setIsOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [isPageValid, setIsPageValid] = useState(false);
  const [formData, setFormData] = useState({
    targetAudience: "",
    platforms: [],
    mockups: "",
    features: "",
    designPreferences: "",
    budget: 0,
  });
  const formTitleRef = React.useRef(null);
  React.useEffect(() => {
    if (formTitleRef.current && page !== 0) {
      formTitleRef.current.scrollIntoView({ behavior: "smooth" });
    }
    
  }, [page]);
  const SpringModal = ({ isOpen, setIsOpen }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
            className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
          >
            <motion.div
              initial={{ scale: 0, rotate: "12.5deg" }}
              animate={{ scale: 1, rotate: "0deg" }}
              exit={{ scale: 0, rotate: "0deg" }}
              onClick={(e) => e.stopPropagation()}
              className="bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
            >
              <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
              <div className="relative z-10">
                <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
                  <FiAlertCircle />
                </div>
                <h3 className="text-3xl font-bold text-center mb-2">
                  Are you ready to submit?
                </h3>
                <p className="text-center mb-6">
                  If you're ready to submit your form, please click next.
                </p>
                <div className="flex gap-2">
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                  >
                    Nah, go back
                  </button>
                  <button
                  type="submit"
                  onClick={() => {
                    sendEmail();
                    navigate("/form-success", { state: formData });
                  }}
                    className="bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded"
                  >
                    Next!
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };
  const FormTitles = [
    "Client Information",
    "App Overview",
    "Features and Functionality",
  ];
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm();
  const PageDisplay = () => {
    if (page === 0) {
      return (
        <div className="lg:h-[300px]">
          <ClientInformation
            formData={formData}
            setFormData={setFormData}
            register={register}
            errors={errors}
            setValue={setValue}
            isDirty={isDirty}
          />
        </div>
      );
    } else if (page === 1) {
      return (
        <div className="lg:h-[300px]">
          <AppOverview
            formData={formData}
            setFormData={setFormData}
            register={register}
            errors={errors}
          />{" "}
        </div>
      );
    } else {
      return (
        <div className="lg:h-[300px]">
          <Features formData={formData} setFormData={setFormData} />
        </div>
      );
    }
  };

  const navigate = useNavigate();
  return (
    <form
    ref={formTitleRef}
      className="flex flex-col space-y-6 rounded-xl bg-white h-[100%]  px-16 py-12 mt-12 w-4/5 mx-auto text-base"
      style={{
        boxShadow: "0px 8px 40px -2px rgba(6, 12, 59, 0.08)",
      }}
      onSubmit={handleSubmit((data) => {
        console.log(data);
      })}
      noValidate
    >
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">{FormTitles[page]}</h1>
        <h1 className="text-lg">
          {page + 1}/{FormTitles.length}
        </h1>
      </div>
      <div className="w-full">{PageDisplay()}</div>
      <br />
      <div className="flex justify-evenly lg:w-2/5 lg:mx-auto  pt-10">
        {page >= 1 && (
        <button
          className={`
    relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
    border-violet-300 px-4 py-2 font-semibold uppercase text-violet-300 transition-all duration-500
    
    before:absolute before:inset-0
    before:-z-10 before:translate-x-[150%]
    before:translate-y-[150%] before:scale-[2.5]
    before:rounded-[100%] before:bg-violet-300
    before:transition-transform before:duration-1000
    before:content-[""]

    hover:scale-105 hover:text-neutral-900
    hover:before:translate-x-[0%]
    hover:before:translate-y-[0%]
    active:scale-95`}
          type="button"
          onClick={() => {
            setPage((currentPage) => currentPage - 1);
          }}
        >
          Prev
        </button>)}

        {page !== FormTitles.length - 1 && (
          <button
            className={`
          relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
          border-violet-300 px-4 py-2 font-semibold
          uppercase text-violet-300 transition-all duration-500
          
          before:absolute before:inset-0
          before:-z-10 before:translate-x-[150%]
          before:translate-y-[150%] before:scale-[2.5]
          before:rounded-[100%] before:bg-violet-300
          before:transition-transform before:duration-1000
          before:content-[""]
  
          hover:scale-105 hover:text-neutral-900
          hover:before:translate-x-[0%]
          hover:before:translate-y-[0%]
          active:scale-95`}
            type="submit"
            onClick={() => {
         

              if (isValid) {
                setPage((currentPage) => currentPage + 1);
        
              }
            }}
          >
            Next
          </button>
        )}
        {page === FormTitles.length - 1 && (
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className="text-white bg-[#312ECB] hover:bg-blue-800 focus:ring-4  focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-4 md:mr-2    focus:outline-none  "
            type="submit"
          >
            Submit
          </button>
        )}
        <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </form>
  );
};

export default MobileAppForm;
