import React from 'react'

const Customize = () => {
  return (
    <section class="py-12 bg-gray-900 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid items-center grid-cols-1 sm:grid-cols-2 sm:gap-y-12 sm:gap-x-12 gap-y-6 lg:grid-cols-3 lg:gap-x-16">
            <div class="lg:order-2 sm:col-span-2 lg:col-span-1">
                <img class="object-cover w-full h-full rounded-xl" src="https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/product-overview/2/product-1.png" alt="" />
            </div>

            <div class="mt-4 lg:order-1 sm:mt-0">
                <ul class="space-y-6 sm:space-y-12 xl:space-y-16">
                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">Superpower</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>

                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">New Design</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>

                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">High Quality Sound</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lg:order-3">
                <ul class="space-y-6 sm:space-y-12 xl:space-y-16">
                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">Superfast 5G</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>

                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">Better Camera</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>

                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                        </svg>
                        <div class="ml-6">
                            <h3 class="text-lg font-bold text-gray-200">Cloud Storage</h3>
                            <p class="mt-5 text-sm font-normal text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis ante viverra in arcu et. Nec faucibus arcu, blandit ultrices nec.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

  )
}

export default Customize