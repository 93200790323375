import React from 'react'
import vision from '../images/vision.jpeg'
const Features6 = () => {
  return (
<section class="py-20 lg:py-40 bg-white ">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
        <div class="max-w-xl mx-auto text-center md:text-left">
          <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
            <p class="text-xs font-semibold tracking-widest text-white uppercase">
              Level Up
            </p>
          </div>
          <h2 class="mt-6 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            Your VISION, Our MISSION
          </h2>
          <p class="mt-4 text-base leading-relaxed text-gray-600 text-xl">
          Having great e-commerce services can have a huge impact on your businesses. Explore how we can elevate your online presence.
          </p>
        </div>

            <div>
                <img class="w-full mx-auto" src={vision} alt="" />
            </div>
        </div>
    </div>
</section>


  )
}

export default Features6