import React from 'react'
import { Select, MenuItem, OutlinedInput, InputAdornment } from '@mui/material'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

const BudgetandTimeline = ({ formData, setFormData }) => {
    const isFutureDate = (date) => {
        const today = new Date();
        return date < today ? true : false;
    };
    const formatDate = (selectedDate) => {
        const date = new Date(selectedDate);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        return formattedDate;
      };
      
    return (
        <React.Fragment>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
                <div className="w-full pr-4">
                    <label className="text-[#060C3C] font-medium">
                        20. Budget Range:
                    </label>
                    {/* <input
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="text"
            placeholder="Enter Budget Range..."
            required
            type="text"
            value={formData.budget}
            onChange={(event) => setFormData({...formData, budget: event.target.value})}
        /> */}
                    <br />
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        sx={{ marginTop: 1 }}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        value={formData.budget}
                        onChange={(event) => setFormData({ ...formData, budget: event.target.value })}
                    />
                </div>
                <div className="w-full inline-block">
                    <label
                        className="text-[#060C3C] font-medium"
                        htmlFor="last_name"
                    >
                        21. Desired Launch Date:
                    </label>
                    {/* <input
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            required
            type="text"
            value={formData.launchDate}
            onChange={(event) => setFormData({...formData, launchDate: event.target.value})}
        /> */}

                    <Calendar value={formData.launchDate}
                    className='mt-2'
                        minDate={new Date()}
                        tileDisabled={({ date }) => isFutureDate(date)}
                        onChange={(event) => {
                            const formattedDate = formatDate(event);
                            setFormData({ ...formData, launchDate: formattedDate });
                          }}/>
                        
                </div>
            </div>
        </React.Fragment>
    )
}

export default BudgetandTimeline