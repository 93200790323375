import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { FiArrowRight, FiMail, FiMapPin } from "react-icons/fi";
import {  SiTiktok,  SiFacebook, SiLinkedin, SiInstagram  } from "react-icons/si";
import logo from '../images/logo-transparent-png-Photoroom-removebg-preview.png'
 const AnimatedGrid = () => {
  return (
    <div className="min-h-screen bg-white px-4 py-40 text-zinc-50">
      <Logo />
      <motion.div
        initial="initial"
        animate="animate"
        transition={{
          staggerChildren: 0.05,
        }}
        className="mx-auto grid max-w-4xl grid-flow-dense grid-cols-12 gap-4"
      >
        <AboutBlock />
        <HeaderBlock />       
        <SocialsBlock />
        {/* <MissionBlock /> */}

      </motion.div>
      <Footer />
    </div>
  );
};

const Block = ({ className, ...rest }) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0,
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1,
        },
      }}
      transition={{
        type: "spring",
        mass: 3,
        stiffness: 400,
        damping: 50,
      }}
      className={twMerge(
        "col-span-4 rounded-lg border border-zinc-700 bg-zinc-800 p-6",
        className
      )}
      {...rest}
    />
  );
};

const HeaderBlock = () => (
  <Block className="col-span-12 row-span-2 md:col-span-6">
    <img
      src={logo}
      alt="avatar"
      className="mb-4 size-14 rounded-full"
    />
    <h1 className="mb-12 text-4xl font-medium leading-tight">
    At E&E Media LLC, we’re passionate about creating digital experiences that leave a lasting impact.
    </h1>
    <a
      href="/contact"
      className="flex items-center gap-1 text-purple-300 hover:underline"
    >
      Contact me <FiArrowRight />
    </a>
  </Block>
);

const SocialsBlock = () => (
  <>
    <Block
      whileHover={{
        rotate: "2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-blue-500 md:col-span-3"
    >
      <a
      target="_blank"
      rel="noreferrer"
        href="https://www.facebook.com/people/EE-Media-LLC/61560990135373/"
        className="grid h-full place-content-center text-3xl text-white"
      >
        <SiFacebook />
      </a>
    </Block>
    <Block
      whileHover={{
        rotate: "-2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-purple-600 md:col-span-3"
    >
      <a
        href="https://www.instagram.com/e_emediallc"
        className="grid h-full place-content-center text-3xl text-white"
      target="_blank"
      rel="noreferrer"
      >
        <SiInstagram />
      </a>
    </Block>
    <Block
      whileHover={{
        rotate: "-2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-zinc-50 md:col-span-3"
    >
      <a
        href="https://www.tiktok.com/@eemediallc"
        target="_blank"
        rel="noreferrer"
        className="grid h-full place-content-center text-3xl text-black"
      >
        <SiTiktok />
      </a>
    </Block>
    <Block
      whileHover={{
        rotate: "2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-blue-300 md:col-span-3"
    >
      <a
        href="https://www.linkedin.com/in/e-e-media-9a2956315/"
        target="_blank"
        rel="noreferrer"
        className="grid h-full place-content-center text-3xl text-white"
      >
        <SiLinkedin />
      </a>
    </Block>
  </>
);

const AboutBlock = () => (
  <Block className="col-span-12 text-md leading-snug bg-white rounded-xl border-2">
    
    <div className="flex justify-center"> {/* Centering the span element */}
      <span className="text-3xl text-purple-900">About E&E Media LLC</span>
    </div>
    <br/>
    <br/>
   <p className="text-center text-black text-xl leading-7">Welcome to E&E Media, where creative vision meets technical expertise. E&E Media is dedicated to crafting innovative digital solutions that elevate your brand's online presence. With a focus on building strong relationships and a commitment to delivering exceptional results, our team is dedicated to helping businesses like yours succeed in the ever-changing digital landscape.<br/><br/> Whether you're looking to launch a new website, revamp your online strategy, or simply need expert guidance on how to navigate the world of web development, we're here to help.<br/> At E&E Media, we're not just building websites - we're building partnerships that drive results. </p>
  <br />
  </Block>
);

const MissionBlock = () => (
  <Block className="col-span-12 text-md leading-snug">
   <div className="flex justify-center"> {/* Centering the span element */}
      <span className="text-3xl text-purple-300">Our Core Values</span>
    </div>
    <br/>
    <br/>
     <ul>
      {[
         "100% Satisfaction Guarantee: Before we hit the “launch” button, we ensure your complete approval. Your satisfaction is our top priority.",
         "Creative Expertise and Vision: We don’t just follow trends; we set them. Our innovative approach ensures your project stands out in a crowded digital landscape.",
         "Affordable Pricing: Quality doesn’t have to break the bank. We offer competitive pricing without compromising on excellence."
      ].map((item, index) => (
        <li className="text-xl" key={index}>{index + 1}. {item}
        <br/>
        <br/>
        </li>
      ))}
    </ul>
  </Block>
);



const Logo = () => {
  // Temp logo from https://logoipsum.com/
  return (
    <div class="max-w-xl mx-auto text-center mt-4 mb-10">
    <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
      <p class="text-xs font-semibold tracking-widest text-white uppercase">
        about us
      </p>
    </div>
  </div>
  );
};

const Footer = () => {
  return (
    <footer className="mt-12">
      <p className="text-center text-zinc-400">
        E&E Media LLC. Your Vision, Our Mission.
      </p>
    </footer>
  );
};


export default AnimatedGrid;