import React from 'react'
import AnimatedGrid from '../components/AnimatedGrid'

const AboutUs = () => {
  return (
    <section className=''>
<AnimatedGrid />
</section>
  )
}

export default AboutUs