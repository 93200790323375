import React from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import { FormHelperText, TextField, Alert } from "@mui/material";
import "react-phone-input-2/lib/style.css";
const ClientInformation = ({
  formData,
  setFormData,
  register,
  errors,
  setValue,
 
}) => {
  return (
    <React.Fragment>
      <div  className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
        <div className="w-full pr-4">
          <label className="text-[#060C3C] font-medium">1. First Name*</label>
          <input
            className={`${
              errors.firstName?.message ? "border-red-500" : "border-[#B4B6C4]"
            } text-center md:text-left bg-white border  border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block  shadow-none  px-2.5 py-3 mt-[10px]`}
            placeholder="First Name..."
            required
            type="text"
            value={formData.firstName}
            {...register("firstName", {
              required: "Please fill out this field.",
              minLength: {
                value: 3,
                message: "Name must have at least 3 characters.",
              },
            })}
            onChange={(event) => {
              setFormData({
                ...formData,
                firstName: event.target.value,
              });
              setValue("firstName", event.target.value);
            }}
          />
          <br />
          {errors.firstName && (
            <Alert sx={{ mt: 1 }} severity="error">
              {errors.firstName?.message}
            </Alert>
          )}
        </div>
        <div className="w-full inline-block">
          <label className="text-[#060C3C] font-medium" htmlFor="last_name">
            2. Last Name
          </label>
          <input
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Last Name..."
            required
            type="text"
            value={formData.lastName}
            onChange={(event) =>
              setFormData({ ...formData, lastName: event.target.value })
            }
          />
        </div>
        <div className="w-full inline-block">
          <label className="text-[#060C3C] font-medium">
            3. Email Address*
          </label>
          <input
            className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
            id="email"
            placeholder="Email..."
            required
            type="email"
            value={formData.email}
            {...register("email", {
              required: "Please fill out this field.",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Please enter a valid email address.",
              },
            })}
            onChange={(event) => {
              setFormData({
                ...formData,
                email: event.target.value,
              });
              setValue("email", event.target.value);
            }}
          />
          {errors.email && (
            <Alert sx={{ mt: 1 }} severity="error">
              {errors.email?.message}
            </Alert>
          )}
        </div>
        <div className="w-full inline-block">
          <label className="text-[#060C3C] font-medium " htmlFor="phone-number">
            4. Phone Number
          </label>
          <br />
          <ReactPhoneInput
            label=""
            value={formData.phoneNumber}
            country={"us"}
            onlyCountries={["us"]}
            onChange={(value) =>
              setFormData({ ...formData, phoneNumber: value })
            }
            inputStyle={{ width: "100%", height: "auto" }}
            component={TextField}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientInformation;
