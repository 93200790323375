import React from 'react'
import logo from '../images/logo-transparent-png-Photoroom-removebg-preview.png'
const ErrorPage = () => {
  return (
    <div className='py-40 items-center flex flex-col'>
        <img src={logo} alt='' />
        <p>404. That's an error</p>
        <p><span className='text-red-600'>The requested URL was not found on this server.</span> That's all we know.</p>
        </div>
  )
}

export default ErrorPage