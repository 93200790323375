import React, { useRef } from "react";
import Features6 from "../components/Features6";
import WhyBuy from "../components/WhyBuy";
import Contact from "../components/Contact";
import FAQ from "../components/FAQ";
import FinalFeature from "../components/FinalFeature";
import { FiCloudLightning, FiLogIn } from "react-icons/fi";
import { useScroll, motion, useTransform } from "framer-motion";
import { useNavigate } from "react-router-dom";
import main from "../images/main.jpeg";
import web from "../images/webdesign.jpeg";
import mobile from "../images/mobile.jpeg";
import graphic from "../images/graphic.jpeg";
import {
  FiArrowRight,
  FiAward,
  FiCalendar,
  FiCopy,
  FiDatabase,
} from "react-icons/fi";
import Features1 from "../components/Features1";
import WhyBuy1 from "../components/WhyBuy1";
import ServGrid from "../components/ServGrid";
import sub1 from '../images/freelogo-removebg-preview.png';
import sub2 from '../images/content-removebg-preview.png';
import sub3 from '../images/bizcard-removebg-preview.png';
const Services = () => {
  const ShimmerBorderCard = () => {
    const navigate = useNavigate();
    const serviceCardData = [
      {
        title: "Web Design & Development Services",
        description:
          "Your online presence matters more than ever. Our team crafts websites that blend aesthetics with functionality. ",
        imgSrc: web,
        href: "/web-form",
        learnDescription:
          "We specialize in creating custom websites that align with your brand identity and business goals. Whether you need a sleek portfolio site, an e-commerce platform, or a corporate web presence, we’ll design and develop a visually appealing, responsive, and user-friendly website.",
        subHeader: "Transform your online presence",
        subTitle: "Web Design that Drives Results",
        subText: "Elevate your brand and engage your audience with our expert web design services. From modern responsive designs to e-commerce solutions, we create websites that drive traffic, boost conversions, and grow your business",
        subHeader1: "Where Creativity Meets Code",
        subTitle1: "Crafting a Digital Presence That Shines",
        subText1: "From concept to launch, I'll guide you through the web design process, ensuring a website that accurately reflects your brand's unique personality and resonates with your target audience.",
        subHeader2: "Tell Your Story",
        subTitle2: "Building Digital Experiences",
        subText2: "With a focus on user-centered design and cutting-edge technology, I'll create a website that not only looks amazing but also drives results, whether that means generating leads, boosting sales, or simply sharing your story with the world",
        mainTitle: 'Web Design Services',
        mainDesc: 'Transform your online presence with our custom web design solutions, crafted to engage, inform, and convert. From responsive websites to e-commerce platforms, our expert designers create intuitive, user-friendly, and visually stunning experiences that drive results for your business.'
      },
      {
        mainTitle: 'Design and Development Solutions',
        mainDesc: 'Engage your audience and elevate your brand with our high-quality content creation services. ',
        subHeader: "crafting unique identities",
        subTitle: "Logo Design",
        subText:
          "A well-designed logo is the foundation of your brand's visual identity. Our expert logo design services create distinctive, memorable, and scalable logos that represent your brand's values and message.",
        subHeader1: "Compelling Stories, Crafted with Care",
        subTitle1: "Content Creation",
        subText1:
          "Engage your audience and elevate your brand with our high-quality content creation services. From blog posts and articles to social media and website copy, our expert writers craft compelling stories that resonate with your target audience and drive results.",
        subHeader2: "Make a Lasting Impression",
        subTitle2: "Business Cards",
        subText2:
          "Our expertly designed business cards help you stand out from the crowd and make a professional impression. With high-quality materials, precise printing, and creative design, your business cards will become an essential tool for networking and building your brand.",
        title: "Graphic Design Services",
        description:
          "Creativity meets strategy in our graphic designs. We create visuals that resonate with your audience. ",
        href: "/graphic-design",
        imgSrc: graphic,
        learnDescription:
          "Visuals matter! From eye-catching banners to icons and infographics, we’ll create graphics that enhance your brand presence. Whether it’s for social media, newsletters, or promotional materials, our designs will captivate your audience.",
      },
      // {
      //   mainTitle: 'Designing Apps, Elevating Experiences',
      //   mainDesc: 'Our mobile app design service crafts intuitive, user-centered, and visually stunning experiences that bring vision to life and leave lasting impression on your users.',
      //   subHeader: "User-centric Approach",
      //   subTitle: "Understanding Your Users",
      //   subText: "Our user research and analysis ensure that we design apps that meet the needs and exceed the expectations of your target audience.",
      //   subHeader1: "Innovative Design Solutions",
      //   subTitle1: "Designing for Delight",
      //   subText1: "Our team of expert designers and creatives work together to craft unique, engaging, and user-friendly interfaces that drive user adoption and loyalty",
      //   subHeader2: "Seamless User Experience",
      //   subTitle2: "Streamlining interactions",
      //   subText2: "*We optimize every touchpoint and interaction to create a seamless and intuitive user experience that sets your app apart from the competition.",
      //   title: "Mobile Applications Services",
      //   description:
      //     "Entrepreneurs need apps that drive growth. Our mobile development team specializes in user-friendly experiences. ",
      //   href: "/mobile-apps",
      //   imgSrc: mobile,
      //   learnDescription:
      //     "We specialize in crafting bespoke mobile apps that seamlessly align with your brand vision and business objectives. Whether you’re envisioning an elegant lifestyle app, a robust e-commerce platform, or a cutting-edge corporate solution, we’ll meticulously design and develop an app that captivates users.",
      // },
      {
        mainTitle: 'Protecting Your Online Presence',
        mainDesc: 'Our web maintenance service ensures your website remains secure, fast, and up-to-date, giving you peace of mind and allowing you to focus on growing your business',
        subHeader: "Proactive Security Measures",
        subTitle: "Safeguarding Your Website",
        subText: "We monitor your website's security 24/7, applying updates and patches to prevent vulnerabilities and protect against threats.",
        subHeader1: "Optimized Performance",
        subTitle1: "Speed and Reliability",
        subText1: "Our optimization techniques ensure your website loads quickly and runs smoothly, improving user experience and search engine rankings.",
        subHeader2: "Expert Support",
        subTitle2: "Trusted Guidance",
        subText2: "Our expert team is always available to assist with any issues or questions, providing personalized support and guidance.",
        title: "Web Maintenance & Support",
        description:
          "Focus on your business while we handle the technical details. Our services ensure your site stays secure and up-to-date.",
        imgSrc: main,
        href: "/web-maintenance",
        learnDescription:
          "Keeping your website secure and up-to-date is crucial. We’ll handle software updates, security patches, and backups. With ongoing support, you can focus on your business while we take care of the technical details.",
      },
    ];
    return (
      <section className="py-40">
        <div class="max-w-xl mx-auto text-center mb-20 p-4">
          <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
            <p class="text-xs font-semibold tracking-widest text-white uppercase">
              services
            </p>
          </div>
          <h2 class="mt-6 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            How may we assist you?
          </h2>
          <p class="mt-4 text-xl text-base leading-relaxed text-gray-600">
            Remember, our slogan says it all: “Your vision, our mission.” We’re
            here to turn your ideas into reality and elevate your online
            presence!
          </p>
        </div>
        <div className="flex flex-col p-4 xl:flex-row gap-8 mx-auto justify-center ">
          {serviceCardData.map((data, index) => (
            <div className="" key={index}>
              <div className="h-[500px] group relative mx-auto w-full max-w-sm overflow-hidden rounded-xl bg-slate-800 p-0.5 transition-all duration-500 hover:scale-[1.01] hover:bg-slate-800/50">
                <div className="h-[100%] relative z-10 flex flex-col items-center justify-center overflow-hidden rounded-[7px] bg-slate-900 p-8 transition-colors duration-500 group-hover:bg-slate-800">
                  <FiCloudLightning className="relative z-10 mb-10 mt-2 rounded-full border-2 border-indigo-500 bg-slate-900 p-4 text-7xl text-indigo-500" />

                  <h4 className="relative text-center z-10 mb-4 w-full text-3xl font-bold text-slate-50">
                    {data?.title}
                  </h4>
                  <p className="relative z-10 text-slate-400 text-xl">
                    {data?.description}
                  </p>
                  <br />
            {data?.title === 'Graphic Design Services' && <br />}
                  <div className="flex gap-8 justify-center">
                    <a href={data?.href}>
                      <button
                        className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
        border-violet-300 px-4 py-2 font-semibold
        uppercase text-violet-300 transition-all duration-500
        
        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-violet-300
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-neutral-900
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
                      >
                        <FiLogIn />
                        <span>Get Started</span>
                      </button>
                    </a>

                    <a href="/single-service">
                      <button
                        onClick={() =>
                          navigate("/single-service", {
                            state: {
                              mainTitle: data?.mainTitle,
                              mainDesc: data?.mainDesc,
                              title: data?.title,
                              description: data?.learnDescription,
                              imgSrc: data?.imgSrc,
                              subHeader: data?.subHeader,
                              subTitle: data?.subTitle,
                              subText: data?.subText,
                              subHeader1: data?.subHeader1,
                              subTitle1: data?.subTitle1,
                              subText1: data?.subText1,
                              subHeader2: data?.subHeader2,
                              subTitle2: data?.subTitle2,
                              subText2: data?.subText2,
                            },
                          })
                        }
                        className={`
        relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
        border-violet-300 px-4 py-2 font-semibold
        uppercase text-violet-300 transition-all duration-500
        
        before:absolute before:inset-0
        before:-z-10 before:translate-x-[150%]
        before:translate-y-[150%] before:scale-[2.5]
        before:rounded-[100%] before:bg-violet-300
        before:transition-transform before:duration-1000
        before:content-[""]

        hover:scale-105 hover:text-neutral-900
        hover:before:translate-x-[0%]
        hover:before:translate-y-[0%]
        active:scale-95`}
                      >
                        <FiLogIn />
                        <span>Learn more</span>
                      </button>
                    </a>
                  </div>
                </div>

                <motion.div
                  initial={{ rotate: "0deg" }}
                  animate={{ rotate: "360deg" }}
                  style={{ scale: 1.75 }}
                  transition={{
                    repeat: Infinity,
                    duration: 3.5,
                    ease: "linear",
                  }}
                  className="absolute inset-0 z-0 bg-gradient-to-br from-indigo-200 via-indigo-200/0 to-indigo-200 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  };

  return (
    <React.Fragment>
      <ShimmerBorderCard />
      <ServGrid />
      <Features6 />
     <WhyBuy1 />
      <Contact />
      <FAQ />
    </React.Fragment>
  );
};

export default Services;
