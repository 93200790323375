import React from 'react'
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

const DesignPreferences = ({formData, setFormData}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const styles = [
        'Modern',
        'Minimalistic',
        'Playful',
        'Industrial',
        'Scandinavian',
        'Bohemian',
        'Mid-Century Modern',
        'Other'
    ]
    const [newStyles, setNewStyle] = React.useState([]);
            // Store the selected checkbox values in local storage whenever they change
React.useEffect(() => {
  localStorage.setItem('storedStyles', JSON.stringify(newStyles));
}, [newStyles]);


        // Retrieve the stored checkbox values from local storage on component mount
React.useEffect(() => {
    const storedStyles = localStorage.getItem('storedStyles');
    if (storedStyles) {
      setNewStyle(JSON.parse(storedStyles));
    }
  }, []);
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setNewStyle(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

  const handlePageRefresh = () => {
    // Clear stored features on page refresh
    localStorage.removeItem('storedStyles');
    setNewStyle([]); // Clear the state as well
  };

  // Event listener for page refresh
  React.useEffect(() => {
    window.addEventListener('beforeunload', handlePageRefresh);
    return () => {
      window.removeEventListener('beforeunload', handlePageRefresh);
    };
  }, []);
  // ...

  const [color, setColor] = React.useState("")

  const handleColorChange = (event) => {
      setColor(event.target.value);
  }

  console.log(formData.styleSelected)
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
       11. Select your theme color:
        </label>
        {/* <input
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="text"
            placeholder="Enter text here..."
            required
            type="text"
            value={formData.colorScheme}
            onChange={(event) => setFormData({...formData, colorScheme: event.target.value})}
        /> */}

		<div className='flex flex-col items-center mt-3'>
      <p>Click here to select color:</p>
    <input className='w-[75px] h-[50px] p-2 rounded-2xl border border-solid border-[hsl(0, 0, 80)] border-[3px]' type='color' value={formData.colorScheme} onChange={(event) => {
      setFormData({...formData, colorScheme: event.target.value});
      handleColorChange(event);
      }} />
      <div className='w-full h-[200px] items-center transition duration-250 ease-in justify-center rounded-2xl mt-3 flex border rounded-3 border-solid border-[hsl(0,0,80%)] border-[5px]' style={{backgroundColor: formData.colorScheme}}>
            <p>{formData.colorScheme}</p>
      </div>
    </div>
     
    </div>
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
          12. Style (e.g., Modern, Minimalistic, Playful)
        </label>
        <br/>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={formData?.styleSelected === 0 ? null : formData.styleSelected}
          onChange={(event) => {
            handleChange(event);
            setFormData({ ...formData, styleSelected: event.target.value });
          }}
          renderValue={value => {
            if (value.length === 0) {
              return <em>Enter text here...</em>;
            }
            return value;
          }}
          className='w-[100%]'
          MenuProps={MenuProps}
        >
          {styles.map((style) => (
            <MenuItem key={style} value={style}>
              <Checkbox checked={formData.styleSelected.includes(style) } />
              <ListItemText primary={style} />
            </MenuItem>
          ))}
        </Select>
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
    13. Websites You Like (Provide URLs):
    </label>
    <textarea
        className="w-full max-h-28 text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="Enter text here..."
        type="email"
        value={formData.websiteURLS}
        onChange={(event) => setFormData({...formData, websiteURLS: event.target.value})}
    />
</div>
</div>
</React.Fragment>
  )
}

export default DesignPreferences