import React, { useRef } from "react";
import { useScroll, useTransform, motion } from "framer-motion";

 const WhyBuy1 = () => {
  return (
    <>
      <div className="relative h-fit bg-black">
        <Features />
      </div>


    </>
  );
};

const Features = () => {
  return (
    <div className="relative mx-auto grid h-fit w-full max-w-7xl grid-cols-1 gap-8 px-4 md:grid-cols-2">
      <Copy />
      <Carousel />
    </div>
  );
};

const Copy = () => {
  return (
    <div className="flex  w-full flex-col justify-center py-12 md:sticky md:top-0 md:h-screen">
       <div class="max-w-xl mx-auto text-center md:text-left">
          <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
            <p class="text-xs font-semibold tracking-widest text-white uppercase">
              get it done right
            </p>
          </div>
          <h2 class="mt-6 text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">
          Our 4 Steps To Bring Your Vision To Life
          </h2>
          <p class="mt-4 text-base leading-relaxed text-gray-200 text-xl">
          We’re here to turn your ideas into reality and elevate your online presence!
          </p>
        </div>
    </div>
  );
};

const Carousel = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  return (
    <div className="relative  w-full">
  <Buffer />
      <div ref={ref} className="relative z-0 flex flex-col gap-6 md:gap-12">
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={1}
          numItems={4}
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={2}
          numItems={4}
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={3}
          numItems={4}
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={4}
          numItems={4}
        />
      </div>

      <Buffer />
    </div>
  );
};

const CarouselItem = ({ scrollYProgress, position, numItems }) => {
  const stepSize = 1 / numItems;
  const end = stepSize * position;
  const start = end - stepSize;

  const opacity = useTransform(scrollYProgress, [start, end], [1, 0]);
  const scale = useTransform(scrollYProgress, [start, end], [1, 0.75]);

  return (
    <motion.div
      style={{
        opacity,
        scale,
      }}
      className="grid py-10 aspect-video w-full shrink-0 place-content-center rounded-2xl bg-neutral-900"
    >
        {position === 1 ?  
      
              <div className="relative z-10 px-8 flex flex-col items-center justify-center ">
                <img
                  class="w-auto h-10 mx-auto "
                  src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/roadmap/3/Spark.svg"
                  alt=""
                />
                <p class="mt-6 text-2xl font-bold text-white">Step 1</p>
                <p class="mt-5 text-xl font-medium tracking-wide text-purple-500 uppercase">
                  Discover Your Vision
                </p>
                <ul>
                  <li className="mt-3 text-md font-medium list-disc text-gray-200">
                    We listen to your ideas and goals
                  </li>
                  <li className="mt-3 text-md font-medium list-disc text-gray-200">
                    We help you define your project scope and objectives
                  </li>
                  <li className="mt-3 text-md font-medium list-disc text-gray-200">
                    We provide a personalized quote based on your needs
                  </li>
                </ul>
              </div>

 : position === 2 ? 
              <div className="relative z-10 px-8 flex flex-col items-center justify-center">
                <img
                  class="w-auto h-10 mx-auto "
                  src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/roadmap/3/Spark.svg"
                  alt=""
                />
               <p class="mt-6 text-2xl font-bold text-white">Step 2</p>
            <p class="mt-5 text-xl font-medium tracking-wide text-purple-500 uppercase">
              Creative Development
            </p>
            <ul>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                Our experts bring your vision to life with innovative design and
                development solutions
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We collaborate with you to ensure our work meets your
                expectations
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We provide regular updates and demos to keep you informed
              </li>
            </ul>
              </div>

             
          : position === 3 ?  
              <div className="relative z-10 px-8 flex flex-col items-center justify-center ">
                <img
                  class="w-auto h-10 mx-auto "
                  src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/roadmap/3/Spark.svg"
                  alt=""
                />
             <p class="mt-6 text-2xl font-bold text-white">Step 3</p>
            <p class="mt-5 text-xl font-medium tracking-wide text-purple-500 uppercase">
              Launch and Refine
            </p>
            <ul>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We launch your project and make sure it's working perfectly
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We gather your feedback and make and necessary adjustments
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We ensure 100% satisfaction with our work
              </li>
            </ul>
              </div>

            
          : position === 4 ? 
              <div className="relative px-8 z-10 flex flex-col items-center justify-center ">
                <img
                  class="w-auto h-10 mx-auto "
                  src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/roadmap/3/Spark.svg"
                  alt=""
                />
              <p class="mt-6 text-2xl font-bold text-white">Step 4</p>
            <p class="mt-5 text-xl font-medium tracking-wide text-purple-500 uppercase">
              Ongoing Support
            </p>
            <ul>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We provide ongoing maintenance and support to keep your project
                thriving
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We offer affordable pricing options to fit your budget
              </li>
              <li className="mt-3 text-md font-medium list-disc text-gray-200">
                We continue to refine and improve your project based on your
                evolving needs
              </li>
            </ul>
              </div>

             
           : null}
      {/* <span className="text-lg text-neutral-600">Feature demo here</span> */}
    </motion.div>
  );
};



const Buffer = () => <div className="h-12 w-full md:h-24" />;

export default WhyBuy1;