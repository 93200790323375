import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../images/offlogo.png";


const FormSuccess = () => {
  const location = useLocation();
  const formData = location.state;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
//   const formatTitle = (key) => {
//     return key
//       .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
//       .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
//   };
//   const formattedTitles = Object.keys(formData).map((key) => formatTitle(key));
//   console.log(formattedTitles);
  return (
<div className="py-40 p-8" style={{ backgroundColor: '#ffffff', fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif' }}>
<table align="center" width="100%" role="presentation" cellSpacing="0" cellPadding="0" border="0" style={{ maxWidth: '37.5em', margin: '0 auto', padding: '20px 0 48px' }}>
  <tbody>
    <tr style={{ width: '100%' }}>
      <td>
        <img alt="Koala" src={logo} width="auto" height="auto" style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', margin: '0 auto' }} />
        {/* <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Hi {formData?.firstName},</p> */}
        <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Thank you for reaching out to us! We’ve received your information from the form you've submitted, and will be emailing you shortly. Our team is excited to learn more about your project! To discuss your needs further, you can schedule a consultation with us at your convenience.</p>
        <table align="center" width="100%" style={{ textAlign: 'center' }} border="0" cellPadding="0" cellSpacing="0" role="presentation">
          <tbody>
            <tr>
              <td>
                <a href="https://calendly.com/enriquemgreene/e-e-media-llc-service-consultation" target="_blank" rel="noreferrer" style={{ backgroundColor: '#5F51E8', borderRadius: '3px', color: '#fff', fontSize: '16px', textDecoration: 'none', textAlign: 'center', display: 'inline-block', lineHeight: '100%', maxWidth: '100%', padding: '12px 12px 12px 12px' }}>
                  <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%;mso-text-raise: 18;" hidden>&nbsp;</i><![endif]--> */}</span>
                  <span style={{ display: 'inline-block', lineHeight: '120%', maxWidth: '100%', msoPaddingAlt: '0px', msoTextRaise: '9px' }}>Get started</span>
                  <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%" hidden>&nbsp;</i><![endif]--> */}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Best,<br />The E&E Media LLC team</p>
        <hr style={{ border: 'none', borderTop: '1px solid #eaeaea', width: '100%', borderColor: '#cccccc', margin: '20px 0' }} />
        <p style={{ fontSize: '12px', lineHeight: '24px', margin: '16px 0', color: '#8898aa', textAlign: 'center' }}>© Copyright 2024, All Rights Reserved by E&E Media LLC.</p>
      </td>
    </tr>
  </tbody>
</table>
</div>
  );
};

export default FormSuccess;
