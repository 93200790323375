import React from 'react'
import teaser from '../images/teaser_1-removebg-preview.png'
const Hero = () => {
  return (
    <section class="py-40 bg-custom-image">
        <div class="lg:py-12 px-4 mx-auto lg:w-4/5  sm:px-6 lg:px-20">
            <div class="grid items-center grid-cols-1  lg:grid-cols-2">

            <div className='lg:ml-20' >
      
             <div class=" mx-auto text-center xl:text-left">
             <div class="max-w-xl mx-auto  mt-4 mb-5">
    <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
      <p class="text-xs font-semibold tracking-widest text-white uppercase">
        your vision, our mission
      </p>
    </div>
  </div>
                 <h1 class="text-4xl font-bold text-white uppercase sm:text-5xl xl:text-6xl">Let's Design your success story </h1>
                 <p class="max-w-sm mx-auto mt-6  font-normal text-gray-200 text-xl lg:mx-0">At E&E Media LLC, we believe that your vision is the driving force behind our mission. Let's bring your vision to life together!</p>
                 <div class="flex flex-col justify-center px-16 mt-10 space-y-5 sm:px-0 sm:items-center sm:space-x-5 sm:flex-row lg:justify-start sm:space-y-0">
                     <a
                        href="/get-started"
                        title=""
                        class="inline-flex items-center justify-center px-4 py-3 text-xs font-bold tracking-widest uppercase transition-all duration-200 bg-transparent border rounded-md border-rose-400 focus:outline-none text-rose-400 focus:ring-2 focus:ring-offset-2 focus:ring-rose-400 hover:bg-rose-400 hover:text-white"
                        role="button"
                    >
                        Get Started
                        <svg class="w-5 h-5 ml-2 -mr-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>

                    <a
                        href="/services"
                        title=""
                        class="inline-flex items-center justify-center px-4 py-3 text-xs font-bold tracking-widest text-teal-400 uppercase transition-all duration-200 bg-transparent border border-teal-400 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-400 hover:bg-teal-400 hover:text-white"
                        role="button"
                    >
                        View services
                        <svg class="w-5 h-5 ml-2 -mr-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                </div>

                <div class="inline-grid grid-cols-2 mt-12 gap-x-8">
                    <div class="flex flex-col lg:items-center lg:space-x-3 lg:flex-row">
                        <p class="text-4xl font-bold text-white">100%</p>
                        <p class="mt-2 text-md font-medium text-gray-400 lg:mt-0">Satisfaction <br class="hidden lg:block" />Guarantee</p>
                    </div>

                    <div class="flex flex-col lg:items-center lg:space-x-3 lg:flex-row">
                        <p class="text-4xl font-bold text-white">50+</p>
                        <p class="mt-2 text-md font-medium text-gray-400 lg:mt-0">Ideas <br class="hidden lg:block" />Created</p>
                    </div>
                </div>
            </div>
        
        </div>
                <div className='lg:mt-0 mt-20 '>
                    <img class="w-full" src={teaser} alt="" />
                </div>
            </div>
        </div>
    </section>


  )
}

export default Hero
