import React from 'react'

const Courses = () => {
  return (
    <>
    <section class="py-40 bg-white">
    <div class="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-12">
            <div class="flex flex-col self-stretch justify-between">
                <div class="flex-1">
                    <h2 class="tracking-tighter text-black">
                        <span class="font-sans text-4xl font-normal sm:text-5xl md:text-6xl"> Learn how to code <br/> at your own pace!</span>
                    </h2>
                    <p class="mt-6 font-sans text-lg font-normal  text-black">Here at E&E Media, we offer a rich web design and development program to get you started on your coding journey. Whether you are new to coding, or you have some previous experience, let us help you improve your skills!</p>
                    <div class="mt-8">
                        <a
                            href="#"
                            title=""
                            class="
                                inline-flex
                                items-center
                                justify-center
                                px-5
                                py-2
                                font-sans
                                text-base
                                font-semibold
                                transition-all
                                duration-200
                                border-2 border-transparent
                                rounded-full
                                sm:leading-8
                                bg-black
                                sm:text-lg
                                text-white
                                hover:bg-opacity-90
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-secondary
                            "
                            role="button"
                        >
                            Read More
                        </a>
                    </div>
                </div>

                <div class="relative max-w-md mt-8 overflow-hidden rounded-lg group lg:mt-auto">
                    <div class="aspect-w-16 aspect-h-9">
                        <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src="https://cdn.rareblocks.xyz/collection/bakerstreet/images/programs/3/program-big.png" alt="" />
                    </div>
                    <div class="absolute inset-0 bg-gradient-to-t from-secondary via-transparent to-transparent"></div>
                    <div class="absolute left-4 bottom-4">
                        <a
                            href="#"
                            title=""
                            class="
                                inline-flex
                                items-center
                                justify-center
                                px-5
                                py-2
                                font-sans
                                text-base
                                font-semibold
                                transition-all
                                duration-200
                                bg-transparent
                                border-2
                                rounded-full
                                text-white
                                border-primary
                                hover:bg-white
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
                                hover:text-black
                                focus:ring-offset-secondary
                            "
                            role="button"
                        >
                            <svg class="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0416 4.9192C7.37507 4.51928 6.5271 4.99939 6.5271 5.77669L6.5271 18.2232C6.5271 19.0005 7.37507 19.4806 8.0416 19.0807L18.4137 12.8574C19.061 12.469 19.061 11.5308 18.4137 11.1424L8.0416 4.9192Z"></path>
                            </svg>
                            Watch trailer
                        </a>
                    </div>
                </div>
            </div>

            <div class="lg:pl-16">
                <div class="overflow-hidden border divide-y rounded-lg border-neutral divide-neutral">
                    <div class="px-8 py-10 transition-all duration-200 hover:bg-dark-gray">
                        <h3 class="font-sans text-sm font-semibold uppercase text-black">Web Design: Part I</h3>
                        <p class="mt-2 font-serif text-3xl italic text-black">Foundations of Web Development</p>
                        <p class="mt-4 font-sans text-base font-normal  text-black"> In this part, you’ll build a strong foundation by understanding how the web works. You’ll explore HTML for structuring content and CSS for styling. By the end, you’ll create your own basic homepage.</p>
                    </div>

                    <div class="px-8 py-10 transition-all duration-200 hover:bg-dark-gray">
                        <h3 class="font-sans text-sm font-semibold uppercase text-black">Web Design: Part II</h3>
                        <p class="mt-2 font-serif text-3xl italic text-black">Interactive Web with JavaScript</p>
                        <p class="mt-4 font-sans text-base font-normal  text-black">Dive into JavaScript! Learn how to make your web pages dynamic by manipulating the Document Object Model (DOM). You’ll also explore advanced HTML and CSS techniques.</p>
                    </div>

                    <div class="px-8 py-10 transition-all duration-200 hover:bg-dark-gray">
                        <h3 class="font-sans text-sm font-semibold uppercase text-black">Web Design: Part III</h3>
                        <p class="mt-2 font-serif text-3xl italic text-black">Building Dynamic Web Applications</p>
                        <p class="mt-4 font-sans text-base font-normal text-black">Take your skills further! Explore JavaScript libraries, like jQuery, and learn about Bootstrap for responsive design. Dive into user interface (UI) principles and start building a test website.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="py-20  bg-black">
    <div class="px-6 mx-auto sm:px-8 lg:px-12 max-w-7xl">
        <div class="text-center">
            <h2 class="tracking-tighter text-white">
                <span class="font-sans text-4xl font-normal sm:text-5xl md:text-6xl"> E&E Media </span>
                <span class="font-sans font-normal text-4xl sm:text-5xl md:text-6xl"> Coding Camp </span>
            </h2>
            <p class="max-w-md mx-auto mt-6 font-sans text-lg text-opacity-50 text-white">Want to learn more? Check out our curriculum and what we offer!</p>
        </div>

        <div class="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-2 lg:grid-cols-3 lg:mt-16">
            <div class="relative overflow-auto transition-all duration-200 border rounded-lg group border-neutral hover:bg-dark-gray">
                <div class="relative">
                    <div class="overflow-hidden aspect-w-4 aspect-h-2">
                        <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src="https://cdn.rareblocks.xyz/collection/bakerstreet/images/context/1/course-1.png" alt="" />
                    </div>
                    <div class="absolute inline-flex items-center justify-center w-12 h-12 font-sans text-2xl font-normal rounded-full bg-black text-white top-4 left-4">1</div>
                </div>

                <div class="px-5 py-6">
                    <h3 class="font-sans text-2xl font-normal text-white">Foundations of Web Development</h3>
                    <p class="mt-2 font-sans text-lg font-normal text-opacity-50 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="mt-6">
                        <a href="#" title="" class="inline-flex items-center font-sans text-sm font-normal group text-white">
                            Read More
                            <svg class="w-5 h-5 ml-2 transition-all duration-200 transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                            </svg>
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="relative overflow-auto transition-all duration-200 border rounded-lg group border-neutral hover:bg-dark-gray">
                <div class="relative">
                    <div class="overflow-hidden aspect-w-4 aspect-h-2">
                        <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src="https://cdn.rareblocks.xyz/collection/bakerstreet/images/context/1/course-2.png" alt="" />
                    </div>
                    <div class="absolute inline-flex items-center justify-center w-12 h-12 font-sans text-2xl font-normal rounded-full bg-black text-white top-4 left-4">2</div>
                </div>

                <div class="px-5 py-6">
                    <h3 class="font-sans text-2xl font-normal text-white">Interactive Web with JavaScript</h3>
                    <p class="mt-2 font-sans text-lg font-normal text-opacity-50 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="mt-6">
                        <a href="#" title="" class="inline-flex items-center font-sans text-sm font-normal group text-white">
                            Read More
                            <svg class="w-5 h-5 ml-2 transition-all duration-200 transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                            </svg>
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="relative overflow-auto transition-all duration-200 border rounded-lg group border-neutral hover:bg-dark-gray">
                <div class="relative">
                    <div class="overflow-hidden aspect-w-4 aspect-h-2">
                        <img class="object-cover w-full h-full transition-all duration-300 transform group-hover:scale-125" src="https://cdn.rareblocks.xyz/collection/bakerstreet/images/context/1/course-3.png" alt="" />
                    </div>
                    <div class="absolute inline-flex items-center justify-center w-12 h-12 font-sans text-2xl font-normal rounded-full bg-black text-white top-4 left-4">3</div>
                </div>

                <div class="px-5 py-6">
                    <h3 class="font-sans text-2xl font-normal text-white">Building Dynamic Web Applications</h3>
                    <p class="mt-2 font-sans text-lg font-normal text-opacity-50 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="mt-6">
                        <a href="#" title="" class="inline-flex items-center font-sans text-sm font-normal group text-white">
                            Read More
                            <svg class="w-5 h-5 ml-2 transition-all duration-200 transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                            </svg>
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>




<section class="py-40 bg-white ">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid items-center grid-cols-1 gap-10 sm:gap-16 lg:grid-cols-2">
            <div>
                <div class="max-w-sm mx-auto bg-black xl:max-w-md rounded-2xl -rotate-3">
                    <div class="p-6 sm:px-10 sm:py-12">
                        <img class="w-auto h-7" src="https://cdn.rareblocks.xyz/collection/clarity/images/cta/3/crowny-logo.svg" alt="" />

                        <blockquote class="mt-6">
                            <p class="text-lg font-normal leading-relaxed text-white font-pj">“You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.”</p>
                        </blockquote>

                        <div class="flex items-center justify-between mt-8">
                            <div class="flex items-center">
                                <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://cdn.rareblocks.xyz/collection/clarity/images/cta/3/avatar-female.png" alt="" />
                                <div class="ml-3">
                                    <p class="text-lg font-bold text-white font-pj">Jerome Bell</p>
                                    <p class="text-base font-pj font-normal text-gray-600 mt-0.5">CTO, Waverio</p>
                                </div>
                            </div>

                            <img class="w-auto h-12" src="https://cdn.rareblocks.xyz/collection/clarity/images/cta/3/signature.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-md mx-auto text-center lg:max-w-none lg:text-left">
                <h2 class="text-3xl font-bold text-black sm:text-4xl xl:text-5xl font-pj">Get a demo 💪 Build landing pages.</h2>
                <p class="mt-8 text-base font-normal leading-7 text-black font-pj">Create custom landing pages with Rareblocks that converts more visitors than any website. With lots of unique blocks, you can easily build a page.</p>

                <form action="#" method="POST" class="mt-16">
                    <label for="" class="text-lg font-bold text-black font-pj"> Provide email address to get free demo </label>
                    <div class="relative max-w-lg mx-auto mt-5 lg:mx-0">
                        <div class="absolute -inset-2">
                            <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter" style={{background: 'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)'}}></div>
                        </div>

                        <div class="relative">
                            <input
                                type="email"
                                name=""
                                id=""
                                placeholder="Enter your email address"
                                class="block w-full px-5 py-6 text-base font-normal text-white placeholder-gray-400 bg-gray-900 border border-transparent rounded-xl focus:border-white focus:ring-1 focus:ring-white font-pj focus:outline-none"
                            />

                            <div class="mt-4 sm:mt-0 sm:absolute sm:inset-y-0 sm:right-0 sm:flex sm:items-center sm:pr-3">
                                <button
                                    type="submit"
                                    class="
                                        inline-flex
                                        items-center
                                        justify-center
                                        w-full
                                        px-8
                                        py-4
                                        text-base
                                        font-bold
                                        text-gray-900
                                        transition-all
                                        duration-200
                                        bg-white
                                        border border-transparent
                                        sm:py-3 sm:w-auto
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white
                                        font-pj
                                        hover:bg-opacity-90
                                        rounded-xl
                                    "
                                >
                                    Get started now
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

</>
  )
}

export default Courses