import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./Pages/Home.js";
import Footer from "./components/Footer.js";
import GetStarted from "./Pages/GetStarted.js";
import FormSuccess from "./Pages/FormSuccess.js";
import Services from "./Pages/Services.js";
import AboutUs from "./Pages/AboutUs.js";
import ContactPage from "./Pages/ContactPage.js";
import Blog from "./Pages/Blog.js";
import Business from "./Pages/Business.js";
import Customize from "./Pages/Customize.js";
import SingleService from "./Pages/SingleService.js";
import LogoForm from "./Pages/GraphicForm.js";
import MobileApps from "./Pages/MobileApps.js";
import WebForm from './Pages/WebForm.js'
import WebMaintenance from "./Pages/WebMaintenance.js";
import Portfolio from "./components/Portfolio.js";
import Mission from "./Pages/Mission.js";
import Courses from "./Pages/Courses.js";
import FinalFeature from "./components/FinalFeature.js";
import Conditions from "./Pages/Conditions.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy.js";
import ErrorPage from "./Pages/404.js";
function App() {
  return (
  <React.Fragment>
    <Navbar />
    <Routes>
      <Route children path="/" element={<Home />} />
      <Route children path="/mobile-apps" element={<MobileApps />} />
      <Route children path="/graphic-design" element={<LogoForm />} />
      <Route children path="/get-started" element={<GetStarted />} />
      <Route children path="/form-success" element={<FormSuccess/>} />
      <Route children path="/services" element={<Services/>} />
      <Route children path="/about-us" element={<AboutUs/>} />
      <Route children path="/contact" element={<ContactPage />} />
      <Route children path="/blog" element={<Blog />} />
      <Route children path="/customize" element={<Customize />} />
      <Route children path="/business+" element={<Business />} />
      <Route children path="/web-form" element={<WebForm />} />
      <Route children path="/web-maintenance" element={<WebMaintenance />} />
      <Route children path="/portfolio" element={<Portfolio />} />
      <Route children path="/mission" element={<Mission />} />
      <Route children path="/courses" element={<Courses />} />
      <Route children path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route children path="/terms-and-conditions" element={<Conditions />} />
      <Route children path="/single-service" element={<SingleService />} />
      <Route children path="*" element={<ErrorPage />} />
    </Routes>
    <FinalFeature />
    <Footer />
  </React.Fragment>
  );
}

export default App;
