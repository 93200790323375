import Contact from "../components/Contact";
import React from 'react'

const ContactPage = () => {
  return (
    <div className="py-20">
    <Contact />
    </div>
  )
}

export default ContactPage