import React from 'react'

const Mission = () => {
  return (
    <section class="py-12 bg-gray-900 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p class="text-sm font-normal text-gray-300 font-pj">Nov 19, 2021</p>
        <h1 class="max-w-4xl mt-6 text-3xl font-bold text-white sm:text-4xl xl:text-5xl font-pj xl:max-w-5xl">How to create a successful website using Rareblocks UI Kit.</h1>

        <div class="grid grid-cols-1 mt-10 lg:grid-cols-12 lg:mt-24 gap-y-10 lg:gap-x-20">
            <div class="lg:col-span-5">
                <div class="overflow-hidden rounded-2xl lg:h-full max-h-96 lg:max-h-full">
                    <img class="object-cover lg:h-full" src="https://cdn.rareblocks.xyz/collection/clarity/images/long-form/2/img-blog.png" alt="" />
                </div>
                {/* <!-- <div class="w-full h-full overflow-hidden rounded-2xl aspect-w-3 aspect-h-4 sm:aspect-h-3 lg:aspect-none">
              <img class="object-cover w-full h-full lg:w-auto" src="https://cdn.rareblocks.xyz/collection/clarity/images/long-form/2/img-blog.png" alt="">
            </div> --> */}
            </div>

            <div class="lg:col-span-7">
                <p class="text-base font-normal leading-7 text-gray-300 font-pj">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In
                    vitae neque integer integer metus, est, tristique fermentum tristique.
                </p>

                <h2 class="mt-10 text-2xl text-white font-pj font-bodl">Get everything from scratch</h2>
                <p class="mt-6 text-base font-normal leading-7 text-gray-300 font-pj">
                    Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique
                    fermentum tristique.
                </p>

                <ul class="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-300 list-disc list-outside font-pj">
                    <li>Id elementum velit posuere vestibulum lectus id eu sollicitudin.</li>
                    <li>Tellus amet bibendum sit mus phasellus quis duis lacus.</li>
                    <li>Enim eget sagittis, auctor iaculis nulla semper.</li>
                </ul>

                <h2 class="mt-10 text-2xl text-white font-pj font-bodl">Choose from 120+ UI blocks</h2>
                <p class="mt-6 text-base font-normal leading-7 text-gray-300 font-pj">
                    Massa risus sed eget ornare posuere turpis vitae. Id elementum velit posuere vestibulum lectus id eu sollicitudin. Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique
                    fermentum tristique.
                </p>
                <p class="mt-6 text-base font-normal leading-7 text-gray-300 font-pj">Tellus amet bibendum sit mus phasellus quis duis lacus. Enim eget sagittis, auctor iaculis nulla semper. In vitae neque integer integer metus, est, tristique fermentum tristique.</p>
            </div>
        </div>
    </div>
</section>

  )
}

export default Mission