import React from 'react'
import Features3 from '../components/Features3'
import Features2 from '../components/Features2'
import Features4 from '../components/Features4'

const Conditions = () => {
  return (
    <div className='py-20 p-8'>
    <Features3 />
    </div>
  )
}

export default Conditions