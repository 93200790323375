import React from 'react'
import mockup from '../images/mockup1.png'
import { motion } from 'framer-motion'
const Features3 = () => {
  return (
    <section class="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">



            <div>
   
                    <div class="flex flex-col text-justify items-center justify-center mx-auto py-6 sm:py-10">
                        <p class="ml-6 text-lg font-normal  sm:text-xl">
                            TERMS AND CONDITIONS<br/><br/>
                            Last updated June 21, 2024. <br/>

                        </p>
                        <p>AGREEMENT TO OUR LEGAL TERMS</p>
                        <br/>
                        <p>We are E&E Media LLC, a company registered in Fort Wayne, IN.</p>
                        <br/>
                        <p>We operate the website eemediallc.com, as well as any other related products and services that refer or link to these legal terms:</p>
                        <br/>
                        <p>You can contact us by email at e.emediallc@gmail.com</p>
                        <br/>
                        <p>These Legal Terms constitute a legal binding agreement made between you, whether personally or on behalf of an entity, and E&E Medial LLC, concerning your access to and use of our Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these legal terms. IF YOU DO NOT AGREE WITH ALL THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
                        <br/>
                        <p>We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by e.emediallc@gmail.com, as stated in the email message. By continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.</p>
                        <br/>
                        <p>All users who are minors in the jurisidiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or legal guardian read and agree to these legal terms prior to you using the services.</p>
                        <br/>
                        <p>We recommend that you print a copy of these legal terms for your records.</p>
                    </div>
                <br/>

            </div>

    </div>
</section>

  )
}

export default Features3