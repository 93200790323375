import React from 'react'
import { Select, MenuItem, Checkbox, ListItemText, Alert } from '@mui/material';
const WebsiteGoals = ({formData, setFormData, register, setValue, errors, isNextClicked, trigger}) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const selectedFeatures = [
        'Personal Portfolio',
        'Business or Corporate Website',
        'E-commerce Online Store',
        'Blog or News Website',
        'Portfolio or Gallery Website',
        'Non-profit Organization Website',
        'Educational or eLearning Website',
        'Entertainment or Media Website',
        'Health and Wellness Website',
        'Real Estate or Property Website',
        'Other'
    ]
    const [newFeatures, setNewFeatures] = React.useState([]);

    // Retrieve the stored checkbox values from local storage on component mount
React.useEffect(() => {
    const storedFeatures = localStorage.getItem('selectedFeatures');
    if (storedFeatures) {
      setNewFeatures(JSON.parse(storedFeatures));
    }
  }, []);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setNewFeatures(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    // Store the selected checkbox values in local storage whenever they change
React.useEffect(() => {
    localStorage.setItem('selectedFeatures', JSON.stringify(newFeatures));
  }, []);
  

  const handlePageRefresh = () => {
    // Clear stored features on page refresh
    localStorage.removeItem('selectedFeatures');
    setNewFeatures([]); // Clear the state as well
  };

  // Event listener for page refresh
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      handlePageRefresh(event);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const [isInputFilled, setIsInputFilled] = React.useState(false);
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
       8. What is the Purpose of Your Website? (e.g., Showcase Products, Generate Leads):
        </label>
        <textarea
            className="text-center max-h-24 md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="text"
            placeholder="Purpose..."
            type="text"
            value={formData.purpose}
            onChange={(event) => setFormData({...formData, purpose: event.target.value})}
        />
    </div>
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
          9. Who's your target audience?
        </label>
        <textarea
            className="text-center max-h-24 md:text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Target audience..."
            type="text"
            value={formData.targetAudience}
           
            onChange={(event) => { 
                setFormData({
                    ...formData, 
                    targetAudience: event.target.value
                }); setIsInputFilled(event.target.value !== '');
                setValue('targetAudience', event.target.value); 
            }}
        />


    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
   10. Describe Your Business in a Few Sentences:
    </label>
    <textarea
        className="w-full max-h-28 text-center md:text-left bg-white border border-[#B4B6C4] border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none px-2.5 py-3 mt-[10px]"
        id="description"
        placeholder="Description..."
  
        value={formData.description}
       
        onChange={(event) => { 
          setFormData({
              ...formData, 
              description: event.target.value
          }); 
          setValue('description', event.target.value);
          trigger('description') 
      }}
    />
     
</div>

</div>
</React.Fragment>
  )
}

export default WebsiteGoals