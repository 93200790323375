import React from 'react'

const AppOverview = ({formData, setFormData}) => {
  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
           5. Describe your app’s purpose and target audience.
        </label>
        <textarea
            className="text-center md:text-left bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Enter here..."
            required
            type="text"
            value={formData.targetAudience}
            onChange={(event) => setFormData({...formData, targetAudience: event.target.value})}
        />
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
       6. What platforms (iOS, Android) will the app be available on?
    </label>
    <textarea
        className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="Enter here..."
        required
        type="email"
        value={formData.platforms}
        onChange={(event) => setFormData({...formData, platforms: event.target.value})}
    />
</div>
<div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
       7. Do you have wireframes or mockups?
    </label>
    <textarea
        className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
        id="email"
        placeholder="Enter here..."
        required
        type="email"
        value={formData.mockups}
        onChange={(event) => setFormData({...formData, mockups: event.target.value})}
    />
</div>
</div>
</React.Fragment>
  )
}

export default AppOverview