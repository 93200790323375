import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { TextField, Select, OutlinedInput, FormControl, InputLabel, Alert, Checkbox, MenuItem, ListItemText } from '@mui/material';
import { Description } from '@mui/icons-material';

const BusinessDetails = ({formData, setFormData, register, errors, setValue, isDirty, trigger, isNextClicked}) => {
;


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const industries = [
        'Health and Wellness',
        'E-commerce/Retail',
        'Real Estate',
        'Education',
        'Legal Services',
        'Technology/IT',
        'Hospitality/Travel',
        'Entertainment',
        'Nonprofit/Charity',
        'Fashion and Beauty',
        'Other'
    ]
    const [industrySelections, setIndustrySelections] = React.useState([]);

        // Retrieve the stored checkbox values from local storage on component mount
React.useEffect(() => {
    const storedIndustries = localStorage.getItem('storedIndustries');
    if (storedIndustries) {
      setIndustrySelections(JSON.parse(storedIndustries));
    }
  }, []);
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setIndustrySelections(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
        // Store the selected checkbox values in local storage whenever they change
React.useEffect(() => {
    localStorage.setItem('storedIndustries', JSON.stringify(industrySelections));
  }, []);
  
  const handlePageRefresh = () => {
    // Clear stored features on page refresh
    localStorage.removeItem('storedIndustries');
    setIndustrySelections([]); // Clear the state as well
  };

  // Event listener for page refresh
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      handlePageRefresh(event);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  // ...

  const selectedFeatures = [
    'Personal Portfolio',
    'Business or Corporate Website',
    'E-commerce Online Store',
    'Blog or News Website',
    'Portfolio or Gallery Website',
    'Non-profit Organization Website',
    'Educational or eLearning Website',
    'Entertainment or Media Website',
    'Health and Wellness Website',
    'Real Estate or Property Website',
    'Other'
]
const [newFeatures, setNewFeatures] = React.useState([]);

// Retrieve the stored checkbox values from local storage on component mount
React.useEffect(() => {
const storedFeatures = localStorage.getItem('selectedFeatures');
if (storedFeatures) {
  setNewFeatures(JSON.parse(storedFeatures));
}
}, []);

const handleChange1 = (event) => {
  const {
    target: { value },
  } = event;
  setNewFeatures(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value,
  );
};

// Store the selected checkbox values in local storage whenever they change
React.useEffect(() => {
localStorage.setItem('selectedFeatures', JSON.stringify(newFeatures));
}, []);


const handlePageRefresh1 = () => {
// Clear stored features on page refresh
localStorage.removeItem('selectedFeatures');
setNewFeatures([]); // Clear the state as well
};

// Event listener for page refresh
React.useEffect(() => {
const handleBeforeUnload = (event) => {
  handlePageRefresh1(event);
};
window.addEventListener('beforeunload', handleBeforeUnload);
return () => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
};
}, []);

console.log(errors)
console.log(isNextClicked)

  return (
    <React.Fragment>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mx-auto">
    <div className="w-full pr-4">
        <label className="text-[#060C3C] font-medium">
           5. Business Name?* 
        </label>
        <input
                className={`${errors.firstName?.message ? 'border-red-500' : 'border-[#B4B6C4]'} text-center md:text-left bg-white border  border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block  shadow-none  px-2.5 py-3 mt-[10px]`}
                placeholder="Business Name..."
                required
                type="text"
                value={formData.businessName}
                {...register('businessName')}
                onChange={(event) => { 
                    setFormData({
                        ...formData, 
                        businessName: event.target.value
                    }); 
                    setValue('businessName', event.target.value);
                    trigger('businessName') 
                }}
            />
            <br/>
            {/* {isNextClicked === true && errors && errors.businessName?.message  && (
  <Alert sx={{mt:1}} severity="error">{errors.businessName?.message}</Alert>
)} */}


    </div>
    <div className="w-full inline-block">
        <label
            className="text-[#060C3C] font-medium"
            htmlFor="last_name"
        >
           6. Industry/Niche?*
        </label>
        <br/>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={formData.industry === 0 ? industrySelections : formData.industry}
          {...register('industry')}
          onChange={(event) => {
            handleChange(event);
            setFormData({ ...formData, industry: event.target.value });
          }}
          displayEmpty
          renderValue={value => {
            if (value.length === 0) {
              return <em>Enter text here...</em>;
            }
            return value;
          }}
          className='w-[100%]'
          MenuProps={MenuProps}
        >
          {industries.map((industry) => (
            <MenuItem key={industry} value={industry}>
              <Checkbox checked={formData.industry.includes(industry)} />
              <ListItemText primary={industry} />
            </MenuItem>
          ))}
        </Select>
        {/* {isNextClicked === true && industrySelections.length <= 0 && errors.industry?.message  && (
  <Alert sx={{mt:1}} severity="error">{errors.industry?.message}</Alert>
)} */}
    </div>
    <div className="w-full inline-block">
    <label className="text-[#060C3C] font-medium">
    7. What kind of website are you looking for? (e.g., Blog, E-Commerce, Portfolio):*
    </label>
            <br/>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={formData.features === 0 ? newFeatures: formData.features}
          {...register('features')}
          onChange={(event) => {
            handleChange1(event);
            setFormData({ ...formData, features: event.target.value });
          }}
          displayEmpty
          renderValue={value => {
            if (value.length === 0) {
              return <em>Enter text here...</em>;
            }
            return value;
          }}
          className='w-[100%]'
          MenuProps={MenuProps}
        >
          {selectedFeatures.map((feature) => (
            <MenuItem key={feature} value={feature}>
              <Checkbox checked={formData.features.includes(feature)} />
              <ListItemText primary={feature} />
            </MenuItem>
          ))}
        </Select>
        {/* {isNextClicked === true && newFeatures.length <= 0 && errors.features?.message  && (
  <Alert sx={{mt:1}} severity="error">{errors.features?.message}</Alert>
)} */}
</div>

</div>
</React.Fragment>
  )
}

export default BusinessDetails