import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Alert, TextField } from "@mui/material";
import logo from '../images/offlogo.png'
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-2/lib/style.css";
import nick from '../images/nick.webp'
const Contact = () => {
    const { handleSubmit, register, setValue, formState: { errors, isValid } } = useForm();
    const [formData, setFormData] = React.useState({
        fullName: '',
       email: '',
       phoneNumber: '',
       message: '',
    });
const [complete, setComplete] = useState(false)
    const handleSubmit1 = () => {

      
        // Prepare the email template parameters
        const emailParams = {
            from_name: 'E&E Media LLC',
            user_email: formData?.email,
            to_name: formData?.fullName,
        };
      if (isValid) {
        // Send the email using EmailJS
        emailjs.send('service_zrw3gw3', 'template_haq8erl', emailParams, 'Jte4dftsQ1ppT40QC')
          .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
        }


           // Prepare the email template parameters
        const emailParamsToMe = {
            from_name: formData?.fullName,
            to_email: 'enriquemgreene@gmail.com',
            to_name: 'E&E Media',
            message: formData?.message
        };
      
        if (isValid) {
        // Send the email using EmailJS
        emailjs.send('service_zrw3gw3', 'template_471dl49', emailParamsToMe, 'Jte4dftsQ1ppT40QC')
          .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
      };
    }
    
  return (
    <section class="py-12 bg-gray-50 sm:py-16 lg:py-20 xl:py-24">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-16 xl:gap-x-32">
          <div class="flex flex-col self-stretch justify-between">
            <div class="flex-1">
            <div class="inline-flex px-4 py-1.5 mx-auto rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600">
            <p class="text-xs  font-semibold tracking-widest text-white uppercase">
              COntact us
            </p>
          </div>
              <h2 class="text-3xl mt-3 font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Get in touch with <br /> E&E Media
              </h2>
              <p class="mt-4 text-base font-normal leading-7 text-gray-600 text-xl lg:mt-6 lg:leading-8">
              Ready to bring your vision to life? Let's get started! Whether you have a question, need a quote, or want to discuss your project, we're here to help.  Let's collaborate and create something amazing together! We look forward to hearing from you!
              </p>
            </div>

            <div class="relative mt-10 overflow-hidden bg-purple-700 lg:mt-0 rounded-3xl -rotate-3">
              <div class="absolute bottom-0 right-0 -mb-72 -mr-24 border-[6px] opacity-20 border-white rounded-full w-96 h-96"></div>

              <div class="relative p-6 sm:px-8 ">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-yellow-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    class="w-6 h-6 text-yellow-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    class="w-6 h-6 text-yellow-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    class="w-6 h-6 text-yellow-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    class="w-6 h-6 text-yellow-300"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </div>
                <blockquote class="mt-8">
                  <p class="text-xl font-normal text-white">
                    "Any small business owner needing a website designer/creator. Please contact E&E Media LLC! Professional, knowledgable, reliable and economical!"
                  </p>
                </blockquote>
                <div class="flex items-center mt-6">
                  <img
                    class="object-cover rounded-full w-11 h-11 shrink-0"
                    src={nick}
                    alt=""
                  />
                  <div class="ml-4">
                    <p class="text-base font-semibold text-white">Nickolas Magee</p>
                    <p class="text-sm font-normal text-gray-300">
                      Founder, Wesby-Magee Notary Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white shadow-xl rounded-2xl">
            <div class="p-6 sm:p-10">
              <h3 class="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                Send us a message
              </h3>
              {
              !complete ? (
              <form class="mt-8 space-y-6" onSubmit={handleSubmit((data) => {console.log(data)})} noValidate>
                <div>
                  <label for="fullName" class="sr-only">
                    {" "}
                    Your name{" "}
                  </label>
                  <div>
                    <input
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder="Your name"
                      value={formData.fullName}
                      {...register('fullName', { required:  "Please fill out this field." })}
                      onChange={(event) => { 
                          setFormData({
                              ...formData, 
                              fullName: event.target.value
                          }); 
                          setValue('fullName', event.target.value); 
                      }}
                      class="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0"
                    />
                  </div>
                  { errors.fullName?.message && (
                <Alert sx={{mt:1}} severity="error">{errors.fullName?.message}</Alert>
            )}
                </div>

               <div>
                  <label for="email" class="sr-only">
                    {" "}
                    Email address{" "}
                  </label>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email address"
                      value={formData.email}
                      {...register("email", {
                        required: 'Please fill out this field.',
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: 'Please enter a valid email address.',
                        },
                      })}
                      onChange={(event) => { 
                        setFormData({
                            ...formData, 
                            email: event.target.value
                        });
                        setValue('email', event.target.value); 
                    }}
                     
                      class="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0"
                    />
                  </div>
                  {errors.email && (
        <Alert severity="error">
          {errors.email?.message}
        </Alert>
      )}
                </div>

                <div>
                  <label for="phone" class="sr-only">
                    {" "}
                    Phone{" "}
                  </label>
                  <div>
                  <ReactPhoneInput
            label=""
            value={formData.phoneNumber}
            country={"us"}
            onlyCountries={["us"]}
            {...register("phoneNumber")}
            
            onChange={(value) =>
              setFormData({ ...formData, phoneNumber: value })
            }
            inputStyle={{ width: "100%", height: "auto" }}
            component={TextField}
          />
                  </div>
                  {/* {errors.phoneNumber && (
        <Alert severity="error">
          {errors.phoneNumber?.message}
        </Alert>
      )} */}
                </div>

                <div>
                  <label for="message" class="sr-only">
                    {" "}
                    Write your message{" "}
                  </label>
                  <div>
                    <textarea
                      name="email"
                      id="message"
                      {...register("message", { required: 'Please fill out this field.' })}
                      value={formData.message}
                      onChange={(event) => { 
                        setFormData({
                            ...formData, 
                            message: event.target.value
                        }); 
                        setValue('message', event.target.value); 
                    }}
                      placeholder="Write your message"
                      rows="4"
                      class="block w-full px-px py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-b-2 focus:outline-none focus:border-blue-600 focus:ring-0"
                    ></textarea>
                          {errors.message && (
        <Alert severity="error">
          {errors.message?.message}
        </Alert>)}
                  </div>
                  
                </div>

                <button
                  type="submit"
                  class="inline-flex items-center justify-center w-full px-12 py-4 text-base font-medium text-white transition-all duration-200 bg-purple-700 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  onClick={() => {
                    handleSubmit1();
                    if (isValid) {
                      setComplete(true)
                    console.log(errors)
                    }
                }}
               >
                  Send message
                </button>
              </form> ): ( <div className="py-20"><div 
      className="flex flex-col space-y-6 rounded-xl bg-white h-fit px-12 py-12 text-base text-left"
      style={{
        boxShadow: '0px 8px 40px -2px rgba(6, 12, 59, 0.08)'
      }}
    ><div className="w-full inline-block md:pr-4"><div class="pt-16 pb-8 text-center sm:py-8">
    <svg class="w-10 h-10 mx-auto text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
    </svg>
    <h1 class="mt-4 text-2xl font-bold text-gray-900">We received your message!</h1>
    <p class="mt-2 text-sm font-normal text-gray-600">Hang tight, a member of our team will reach out via email shortly!</p>
</div></div></div></div>)} 
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

 